import React from "react";

const TotalWasteDisposedMulti = ({ timePeriods, wasteDisposal }) => {
  // Extract unique categories dynamically based on the specified condition
  const categories = wasteDisposal.reduce((acc, item) => {
    if (item.question_details) {
      // Check for 'column1' first
      let filteredOptions = item.question_details
        .filter((detail) => detail.option_type === "column1")
        .map((detail) => detail.option);

      // If no 'column1' found, check for 'column'
      if (filteredOptions.length === 0) {
        filteredOptions = item.question_details
          .filter((detail) => detail.option_type === "column")
          .map((detail) => detail.option);
      }

      return acc.concat(filteredOptions);
    }
    return acc;
  }, []);

  // Remove duplicates to get unique categories
  const uniqueCategories = [...new Set(categories)];

  // Prepare data for each time period
  const seriesData = Object.keys(timePeriods).map((timePeriod, timeIndex) => {
    const totalValues = uniqueCategories.map((category, categoryIndex) => {
      // Find the corresponding wasteDisposal object for the time period (H1, H2, etc.)
      const timePeriodData = wasteDisposal[timeIndex];
      if (timePeriodData && timePeriodData.question_details) {
        // Check for the matched detail for the category
        const matchedDetail = timePeriodData.question_details.find(
          (detail) => detail.option === category
        );
        if (
          matchedDetail &&
          timePeriodData.answer &&
          timePeriodData.answer[0]
        ) {
          // Extract the value from the corresponding index in the answer array
          const answerValue = timePeriodData.answer[0][categoryIndex]; // Assume answers align with category index
          return answerValue !== undefined ? Number(answerValue) : 0;
        }
      }
      return 0; // Default to 0 if no data is found
    });

    return {
      name: timePeriod,
      data: totalValues,
    };
  });

  // Calculate the total sum for labels
  const totalSum = seriesData.reduce(
    (sum, series) => sum + series.data.reduce((a, b) => a + b, 0),
    0
  );

  // Colors for each category
  const colors = [
    "#C6CB8D",
    "#949776",
    "#ABC4B2",
    "#6D8B96",
    "#9CDFE3",
    "#11546f",
    "#587b87",
    "#8CBBCE",
  ];

  return (
    <div className="container">
      <div className="renewable-bar-header">Total Waste Disposed(in mT)</div>
      <div className="renewable-bar-labels" style={{paddingLeft:"5%"}}>
      <span style={{ fontSize: "11px" }}>0</span>
<span style={{ fontSize: "11px" }}>{totalSum / 5}</span>
<span style={{ fontSize: "11px" }}>{(totalSum / 5) * 2}</span>
<span style={{ fontSize: "11px" }}>{(totalSum / 5) * 3}</span>
<span style={{ fontSize: "11px" }}>{(totalSum / 5) * 4}</span>
<span style={{ fontSize: "11px" }}>{totalSum}</span>

      </div>
      <div className="renewable-bar-dotted-line"></div>

      {seriesData.length > 0 ? (
        <div
          style={{ display: "flex", height:"90%", flexDirection: "column", width: "100%" }}
        >
          {seriesData.map((series, seriesIndex) => (
            <div className="d-flex" style={{width:"100%", marginBottom:"5%"}}>
            <div style={{ fontWeight: 500,color:"#7b91b0", marginBottom: "5px" , paddingTop:"2%", fontSize:"12px", marginRight:"2%"}}>{series.name}</div>
            <div
              key={seriesIndex}
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "5px 0",
                backgroundColor:"#f3f3f3",
                height: "100%",
                width:"100%",
            
              }}
            >
              
              {series.data.map((value, index) => (
  value > 0 && ( // Check if value is greater than 0
    <div
      key={index}
      style={{
        height: "100%", // Adjust height as necessary
        width: `${(value / totalSum) * 100}%`, // Calculate width based on total
        backgroundColor: colors[index % colors.length],
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontWeight: "bold",
        fontSize:"12px"
      }}
      title={`${uniqueCategories[index]}: ${value}`}
    >
      {value}
    </div>
  )
))}

            </div>
            </div>
           
          ))}
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              width: "100%",
              overflow: "auto",
            }}
          >
            {uniqueCategories.map((category, index) => (
              <div
                key={index}
                style={{
                  width: "33%",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      backgroundColor: colors[index % colors.length],
                      marginRight: "5px",
                    }}
                  />
                </div>
                <div style={{ width: "70%" }}>
                  <div style={{ fontSize: "12px" }}>{category}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>No categories available for the selected options.</p>
      )}
    </div>
  );
};

export default TotalWasteDisposedMulti;

// import React, { useState, useEffect } from "react";
// import { apiCall } from "../../../_services/apiCall";
// import config from "../../../config/config.json";
// import Chart from "react-apexcharts"; // Assuming you are using ApexCharts

// const ProductWiseStacked = ({
//   timePeriods,
//   locationOption,
//   product,
//   title,
//   timePeriodValues,
// }) => {
//   const colorMapping = {
//     Electricity: "#83bbd5",
//     Petrol: "#f3a683",
//     Diesel: "#9b59b6",
//     CNG: "#2ecc71",
//     PNG: "#f39c12",
//     LPG: "#e74c3c",
//     "Natural gas": "#3498db",
//     Coal: "#1abc9c",
//     Biomass: "#e67e22",
//     "Energy Consumption through other sources": "#95a5a6",
//   };

//   const [chartSeries, setChartSeries] = useState([]);
//   const [chartOptions, setChartOptions] = useState({
//     chart: {
//       type: "bar",
//       height: 350,
//       stacked: true,
//       stackType: "normal",
//     },
//     plotOptions: {
//       bar: {
//         borderRadius: 5,
//         horizontal: false,
//         columnWidth: "25%",
//         endingShape: "rounded",
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["#fff"],
//     },
//     xaxis: {
//       categories: [], // Will be set dynamically
//       title: {
//         text: "Locations / Time Periods",
//       },
//     },
//     yaxis: {
//       logarithmic: false, // Enable logarithmic scale
//       title: {
//         text: "Energy In GJ",
//       },
//       min: 1, // Set minimum value to avoid logarithm of zero
//       max: 15, // You can adjust this based on your data
//       labels: {
//         formatter: function (value) {
//           return Math.floor(value); // Round down to the nearest whole number
//         },
//       },
//     },
//     fill: {
//       opacity: 1,
//     },
//     tooltip: {
//       y: {
//         formatter: function (val, { seriesIndex, dataPointIndex }) {
//           const logValue = val; // Logarithmic value passed to tooltip
//           const originalValue = Math.pow(10, logValue); // Reverse log to get original value
//           return `${originalValue.toFixed(2)} GJ`; // Display original value in tooltip
//         },
//       },
//     },
//     grid: {
//       borderColor: "#e7e7e7",
//       row: {
//         colors: ["transparent", "transparent"],
//         opacity: 0.5,
//       },
//     },
//   });

//   const getOptions = (product) => {
//     const optionsArray = product
//       .flatMap((item) => item.question_details) // Flatten question_details array
//       .filter((detail) => detail.option_type === "row") // Only "row" types
//       .map((detail) => detail.option); // Extract the option values

//     const uniqueOptions = [...new Set(optionsArray)]; // Remove duplicates

//     // Remove the first option and reverse the array
//     const modifiedOptions = uniqueOptions.slice(1).reverse();

//     return modifiedOptions;
//   };

//   useEffect(() => {
//     if (locationOption.length > 1 && timePeriodValues.length === 1) {
//       const options = getOptions(product); // "Electricity", "Petrol", etc.
//       const categories = locationOption.map((loc) => loc.label); // X-axis labels (locations)

//       const series = options.map((option, optionIndex) => {
//         // For each stack category (Electricity, Petrol, etc.)
//         const data = categories.map((location) => {
//           // Find the location ID corresponding to the label
//           const locationId = locationOption.find(
//             (loc) => loc.label === location
//           ).id;

//           // Sum the values from the product array for this location and option
//           const total = product
//             .filter((item) => item.SourceId === locationId) // Match location
//             .reduce((sum, item) => {
//               const value = item.answer[optionIndex][0]; // Extract the value for the current option
//               const numValue = Number(value);
//               return sum + (isNaN(numValue) || value === "" ? 0 : numValue); // Sum values
//             }, 0);

//           return total; // Return the total for this location and option
//         });

//         return {
//           name: option,
//           data: data,
//           color: colorMapping[option], // Assign color based on option
//         };
//       });

//       // Update chart series and options
//       setChartSeries(series);
//       setChartOptions((prev) => ({
//         ...prev,
//         xaxis: {
//           ...prev.xaxis,
//           categories: categories, // Set x-axis labels (locations)
//         },
//       }));
//     } else if (locationOption.length === 1 && timePeriodValues.length > 1) {
//       const options = getOptions(product); // "Electricity", "Petrol", etc.
//       console.log("Options:", options); // Log options
//       const categories = Object.keys(timePeriods);
//       console.log("Categories:", categories); // Log categories

//       const series = options.map((option, index) => {
//         const data = categories.map((key) => {
//           const filteredData = product.filter(
//             (item) => item.formDate === timePeriods[key]
//           ); // Filter by formDate
//           console.log(`Filtered data for ${key}:`, filteredData); // Log filtered data

//           const summedValue = filteredData.reduce((sum, item) => {
//             const value = item.answer[index] ? item.answer[index][0] : 1; // First element of inner array
//             const numericValue =
//               isNaN(Number(value)) || value === "" || Number(value) === 0
//                 ? 1
//                 : Number(value); // Replace 0 with 1
//             return sum + numericValue;
//           }, 0);

//           // Convert the summedValue to its logarithmic equivalent
//           const logValue = summedValue > 0 ? Math.log10(summedValue) : 0; // Use Math.log for natural log, or Math.log10 for base 10
//           console.log(
//             `Summed value for ${option} at ${key}:`,
//             summedValue,
//             `| Logarithmic value:`,
//             logValue
//           ); // Log both summed and logarithmic values

//           return logValue; // Return the logarithmic value
//         });
//         return {
//           name: option,
//           data: data,
//           color: colorMapping[option], // Assign color based on option
//         };
//       });

//       console.log("Series data:", series); // Log the final series data

//       setChartSeries(series);
//       setChartOptions((prev) => ({
//         ...prev,
//         xaxis: {
//           ...prev.xaxis,
//           categories: categories,
//         },
//       }));
//     } else {
//       // Handle default case or other scenarios similarly to the previous cases
//       const options = getOptions(product); // "Electricity", "Petrol", etc.
//       const categories = Object.keys(timePeriods);

//       const series = options.map((option, index) => {
//         const data = categories.map((key) => {
//           return product
//             .filter((item) => item.formDate === timePeriods[key]) // Filter by formDate
//             .reduce((sum, item) => {
//               const value = item.answer[index][0]; // First element of inner array
//               return (
//                 sum + (isNaN(Number(value)) || value === "" ? 0 : Number(value))
//               );
//             }, 0);
//         });
//         return {
//           name: option,
//           data: data,
//           color: colorMapping[option], // Assign color based on option
//         };
//       });

//       setChartSeries(series);
//       setChartOptions((prev) => ({
//         ...prev,
//         xaxis: {
//           ...prev.xaxis,
//           categories: categories,
//         },
//       }));
//     }
//   }, [locationOption, timePeriodValues, product]);

//   return (
//     <div className="container" style={{ height: "100%" }}>
//       <div style={{ height: "10%" , fontSize:"20px", fontWeight:600,fontColor:"#011627"}}>
//        {title}
//       </div>
//       <div style={{ height: "90%" }}>
//         <Chart
//           options={chartOptions}
//           series={chartSeries}
//           type="bar"
//           height={"100%"}
//         />
//       </div>
//     </div>
//   );
// };

// export default ProductWiseStacked;

import React, { useState, useEffect,useMemo } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import Chart from "react-apexcharts"; // Assuming you are using ApexCharts
import Select, { components } from "react-select"; // Import react-select

const ProductWiseStacked = ({
  timePeriods,
  locationOption,
  product,
  title,
  timePeriodValues,
}) => {
  const colorMapping = {
    Electricity: "#83bbd5",
    Petrol: "#11546f",
    Fuel:"#11546f",
    Diesel: "#65b1b6",
    CNG: "#4a849f",
    PNG: "#86caea",
    LPG: "#e74c3c",
    "Natural gas": "#3498db",
    Coal: "#1abc9c",
    Biomass: "#e67e22",
    "Energy Consumption through other sources": "#95a5a6",
  };

  const [chartSeries, setChartSeries] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]); // State for selected products
  const [productValuesMap, setProductValuesMap] = useState({});
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "normal",
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        const logValue = val; // Logarithmic value passed to tooltip
        const originalValue = Math.pow(10, logValue); // Reverse log to get original value
        return `${originalValue.toFixed(0)} GJ`; // Display original value in tooltip
      },
      offsetY: 0, // Center the label vertically inside the bar
      style: {
        fontSize: "8px",
        colors: ["#fff"], // White text inside bars
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    xaxis: {
      categories: [], // Will be set dynamically
      title: {
        text: "Time Periods",
        style: {
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "Arial",
          color: "#011627",
        },
      },
      labels: {
        style: {
          colors: "#7b91b0",
          fontSize: "12px",
          fontFamily: "Poppins",
        },
      },
    },
    yaxis: {
      logarithmic: false, // Enable logarithmic scale
      title: {
        text: "Energy (GJ)",
        style: {
          fontSize: "8px",
          fontWeight: 400,
          fontFamily: "Arial",
          color: "#011627",
        },
      },

      min: 1, // Set minimum value to avoid logarithm of zero
      max: 15, // You can adjust this based on your data
      labels: {
        style: {
          colors: ["#7b91b0"],
          fontSize: "12px",
          fontFamily: "Poppins",
        },
        formatter: function (value) {
          return Math.floor(value); // Round down to the nearest whole number
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val, { seriesIndex, dataPointIndex }) {
          const logValue = val; // Logarithmic value passed to tooltip
          const originalValue = Math.pow(10, logValue); // Reverse log to get original value
          return `${originalValue.toFixed(2)} GJ`; // Display original value in tooltip
        },
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
  });

  useEffect(() => {
    // Initial data mapping for all products
    const initialProductValuesMap = {};

    const categories = Object.keys(timePeriods);
    const allOptions = getOptions(product); // Fetch all product options

    allOptions.forEach((option, index) => {
      const data = categories.map((key) => {
        const filteredData = product.filter(
          (item) => item.formDate === timePeriods[key]
        );

        const summedValue = filteredData.reduce((sum, item) => {
          const value = item.answer[index] ? item.answer[index][0] : 0; // Set to 0 if undefined
          return sum + (isNaN(Number(value)) || value === "" ? 0 : Number(value));
        }, 0);

        return summedValue; // Collect summed values for each time period
      });

      initialProductValuesMap[option] = data; // Store the mapped data
    });

    setProductValuesMap(initialProductValuesMap); // Set the permanent mapping
  }, [timePeriods, product]); // Only run once when the component mounts


  // Function to get the product options (e.g., "Electricity", "Petrol", etc.)
  const getOptions = (product) => {
    const optionsArray = product
      .flatMap((item) => item.question_details) // Flatten question_details array
      .filter((detail) => detail.option_type === "row") // Only "row" types
      .map((detail) => detail.option); // Extract the option values

    const uniqueOptions = [...new Set(optionsArray)]; // Remove duplicates
    const modifiedOptions = uniqueOptions.slice(1).reverse(); // Remove the first option and reverse the array

    return modifiedOptions;
  };

  // Multi-select options for react-select
  const productOptions = useMemo(() => {
    return getOptions(product).map((option) => ({
      label: option,
      value: option,
    }));
  }, [product]);

  useEffect(() => {
    if (productOptions.length > 0) {
      // Select the first five products
      const firstFiveProducts = productOptions.slice(0, 5);
      setSelectedProducts(firstFiveProducts);
    }
  }, [productOptions]); // Runs when productOptions changes

  useEffect(() => {
    if (locationOption.length > 1 && timePeriodValues.length === 1) {
      const categories = locationOption.map((loc) => loc.label); // X-axis labels (locations)

      const series = selectedProducts.map((selectedProduct) => {
        const optionIndex = getOptions(product).indexOf(selectedProduct.value);

        const data = categories.map((location) => {
          const locationId = locationOption.find(
            (loc) => loc.label === location
          ).id;

          const total = product
            .filter((item) => item.SourceId === locationId) // Match location
            .reduce((sum, item) => {
              const value = item.answer[optionIndex][0]; // Extract the value for the current option
              const numValue = Number(value);
              return sum + (isNaN(numValue) || value === "" ? 0 : numValue); // Sum values
            }, 0);

          return total; // Return the total for this location and option
        });

        return {
          name: selectedProduct.value,
          data: data,
          color: colorMapping[selectedProduct.value], // Assign color based on option
        };
      });

      setChartSeries(series);
      setChartOptions((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: categories, // Set x-axis labels (locations)
        },
      }));
    } else if (locationOption.length === 1 && timePeriodValues.length > 1) {
      // const options =
      //   selectedProducts.length > 0
      //     ? selectedProducts.map((p) => p.value)
      //     : getOptions(product); // Get selected products or all products
      // console.log("Options:", options); // Log options
      // const categories = Object.keys(timePeriods);
      // console.log("Categories:", categories); // Log categories

      // const series = options.map((option, index) => {
      //   const data = categories.map((key) => {
      //     const filteredData = product.filter(
      //       (item) => item.formDate === timePeriods[key]
      //     ); // Filter by formDate
      //     console.log(`Filtered data for ${key}:`, filteredData); // Log filtered data

      //     const summedValue = filteredData.reduce((sum, item) => {
      //       const value = item.answer[index] ? item.answer[index][0] : 1; // First element of inner array
      //       const numericValue =
      //         isNaN(Number(value)) || value === "" || Number(value) === 0
      //           ? 1
      //           : Number(value); // Replace 0 with 1
      //       return sum + numericValue;
      //     }, 0);

      //     // Convert the summedValue to its logarithmic equivalent
      //     const logValue = summedValue > 0 ? Math.log10(summedValue) : 0; // Use Math.log for natural log, or Math.log10 for base 10
      //     console.log(
      //       `Summed value for ${option} at ${key}:`,
      //       summedValue,
      //       `| Logarithmic value:`,
      //       logValue
      //     ); // Log both summed and logarithmic values

      //     return logValue; // Return the logarithmic value
      //   });
      //   return {
      //     name: option,
      //     data: data,
      //     color: colorMapping[option], // Assign color based on option
      //   };
      // });

      // console.log("Series data:", series); // Log the final series data

      // setChartSeries(series);
      // setChartOptions((prev) => ({
      //   ...prev,
      //   xaxis: {
      //     ...prev.xaxis,
      //     categories: categories,
      //   },
      // }));
      const series = selectedProducts.map((selectedProduct) => {
        const data = productValuesMap[selectedProduct.value] || [];
        return {
          name: selectedProduct.value,
          data: data.map(value => (value > 0 ? Math.log10(value) : 0)), // Convert to log values
          color: colorMapping[selectedProduct.value],
        };
      });
  
      setChartSeries(series);
      setChartOptions((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: Object.keys(timePeriods),
        },
      }));

    } else {
      // Handle default case or other scenarios similarly to the previous cases
      const options =
        selectedProducts.length > 0
          ? selectedProducts.map((p) => p.value)
          : getOptions(product); // Get selected products or all products
      const categories = Object.keys(timePeriods);

      const series = options.map((option, index) => {
        const data = categories.map((key) => {
          return product
            .filter((item) => item.formDate === timePeriods[key]) // Filter by formDate
            .reduce((sum, item) => {
              const value = item.answer[index][0]; // First element of inner array
              return (
                sum + (isNaN(Number(value)) || value === "" ? 0 : Number(value))
              );
            }, 0);
        });
        return {
          name: option,
          data: data,
          color: colorMapping[option], // Assign color based on option
        };
      });

      setChartSeries(series);
    }
  }, [locationOption, timePeriodValues, product, selectedProducts]);

  // Handle the change in selected products
  const handleProductChange = (selected) => {
    if (selected.length === 0) {
      alert("You must select at least one product");
      return; // Prevent selecting zero products
    }
    if (selected.length > 5) {
      alert("You can only select up to 5 products");
      return; // Prevent selecting more than 5 products
    }
    setSelectedProducts(selected); // Update selected products state
  };
  

  const CustomOption = (props) => {
    const { isSelected, data } = props;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Square Box */}
          <div
            style={{
              width: "20px",
              height: "20px",
              border: "2px solid #3f88a5",
              borderRadius: "2px",
              backgroundColor: isSelected ? "transparent" : "transparent",
              marginRight: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Tick mark when selected */}
            {isSelected && (
              <span style={{ color: "white", fontSize: "14px" }}>✔</span>
            )}
          </div>
          {/* Option Label */}
          <span style={{ fontSize: "14px", fontWeight: 300 }}>
            {data.label}
          </span>
        </div>
      </components.Option>
    );
  };

  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value, placeholder } = selectProps;
  
    return (
      <components.Control {...props}>
        {/* Placeholder or selected value */}
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          >
            {placeholder}
          </div>
        )}
        {/* Display only the first selected product */}
        {value && value.length > 0 && (
          <div style={{ color: "#3f88a5", marginLeft: "5px" }}>
            {value[0].label}
          </div>
        )}
  
        {/* Ensure you still render the child components like the dropdown indicator and input */}
        {props.children}
      </components.Control>
    );
  };
  const CustomClearIndicator = () => null; 
  return (
    <div className="container" style={{ height: "100%" }}>
      <div
        style={{
          height: "10%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "40%",
            fontSize: "20px",
            fontWeight: 600,
            color: "#011627",
          }}
        >
          {title}
        </div>

        <div style={{ width: "35%" }}>
          <Select
            isMulti
            options={productOptions}
            value={selectedProducts}
            onChange={handleProductChange}
            placeholder="Select Products"
            hideSelectedOptions={false} // Keep selected options in the dropdown
            className=""
            components={{
              Option: CustomOption,
              Control: CustomControl,
              MultiValue: CustomMultiValue,
              ClearIndicator:CustomClearIndicator
            }}
            closeMenuOnSelect={false} // Prevent dropdown from closing
            styles={{
              control: (base) => ({
                ...base,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 100, // Ensure the menu appears above other elements
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#3f88a5", // Change color of the dropdown arrow
                padding: "0 10px", // Adjust padding for the indicator
                fontSize: "20px", // Increase the font size of the indicator
                minHeight: "20px", // Set a minimum height for the indicator
                minWidth: "20px", // Set a minimum width for the indicator
              }),
              placeholder: (base) => ({
                ...base,
                position: "absolute", // Ensure the placeholder doesn't shift with input
                top: "50%",
                transform: "translateY(-50%)", // Vertically center the placeholder
                pointerEvents: "none", // Disable interaction on the placeholder
              }),
              multiValue: (base) => ({
                ...base,
                background: "transparent",
                border: "2px solid #3f88a5",
                borderRadius: "10px",
                marginTop: "0.5em",
              }),
              option: (provided, state) => ({
                ...provided,

                backgroundColor: state.isSelected
                  ? "transparent" // Selected option background color
                  : state.isFocused
                  ? "white" // Focused option background color
                  : "white", // Default option background color
                color: state.isSelected ? "black" : "black", // Text color based on state
                cursor: "pointer",
              }),
            }}
          />
        </div>
      </div>
      {/* Multi-select dropdown for selecting products */}

      <div style={{ height: "85%", marginTop: "5%" }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={"100%"}
        />
      </div>
    </div>
  );
};

export default ProductWiseStacked;

import React from "react";

const EnergyConsumptionCard = ({
  totalConsumptionRenewable,
  timePeriodValues,
  totalConsumptionNonRenewable,
  timePeriods,
}) => {
  return (
    <div className="container" style={{ width: "100%" }}>
      <div style={{ display: "flex", width: "100%" }}>
        <div className="energy-card-content" style={{ width: "70%" }}>
        <h4 className="energy-period">
  {timePeriodValues.length > 1
    ? "Combined"
    : Object.keys(timePeriods)[0].charAt(0).toUpperCase() + Object.keys(timePeriods)[0].slice(1)}
</h4>

          <div className="energy-value">Total Energy</div>
          <div className="energy-value">Consumption (in GJ)</div>
        </div>
        <div style={{width:"30%", display:"flex", alignItems:"center", justifyContent:"center"}}>
        <div className="energy-value-box" style={{ background: "#e2eafd",width:"100%",height:"8vh",borderRadius:"10px", display:"flex", alignItems:"center", justifyContent:"center" }}>
          <div className="energy-value" style={{ color: "#0057a7" , fontSize:"20px", fontWeight:600}}>{`${
            totalConsumptionRenewable + totalConsumptionNonRenewable
          }`}</div>

        </div>
      
        </div>
      </div>
    </div>
  );
};

export default EnergyConsumptionCard;

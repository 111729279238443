import React from "react";
import Chart from "react-apexcharts";

const EnergyConsumptionChart = ({
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
}) => {
  const maxConsumption = Math.max(totalConsumptionRenewable, totalConsumptionNonRenewable);
  const yAxisMax = maxConsumption + 10000; // Add 10,000 to the higher value

  // Chart options
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 5,
        distributed: true,
        endingShape: "rounded",
        dataLabels: {
          position: "center", // place labels inside the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + " GJ"; // Display values inside bars
      },
      offsetY: 0, // Center the label vertically inside the bar
      style: {
        fontSize: "10px",
        colors: ["#fff"], // White text inside bars
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Renewable Energy", "Non-Renewable Energy"], // X-Axis Labels
      labels: {
        style: {
          colors: '#7b91b0',  // Light blue color for the y-axis labels
          fontSize: '12px',   // Adjust the label font size if needed
          fontFamily: 'Poppins',
        },
      },
    },
    yaxis: {
      title: {
        text: "Energy in GJ",
        style: {
          colors: '#7b91b0',  // Light blue color for the y-axis labels
          fontSize: '12px',   // Adjust the label font size if needed
          fontFamily: 'Poppins',
        },
      },
      labels: {
        formatter: function (value) {
          return Math.round(value); // Round off to the nearest whole number
        },
        style: {
          colors: '#7b91b0',  // Light blue color for the y-axis labels
          fontSize: '12px',   // Adjust the label font size if needed
          fontFamily: 'Poppins',
        },
      },
      min: 0,
      max: yAxisMax, // Set dynamic max value
      tickAmount: 5, // Equivalent to 300 increments (0, 300, 600, 900, 1200, 1500)
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " GJ";
        },
      },
    },
    colors: ["#004b68", "#a1d6e2"], // Matches the bar colors in the image
    grid: {
      strokeDashArray: 3, // Dotted gridlines
    },
    legend: {
      show: true,
      markers: {
        width: 0, // Hides default legend markers (squares)
        height: 0,
      },
      position: "bottom", // Adjust as necessary (top, right, bottom, left)
      horizontalAlign: "center", // Align legend items in the center
      itemMargin: {
        horizontal: 10, // Space between legend items
        vertical: 0, // Vertical space (if needed)
      },
      formatter: function (seriesName, opts) {
        return `<div style="display: flex; align-items: center;">
                  <div style="background-color: ${
                    opts.w.config.colors[opts.seriesIndex]
                  }; width: 12px; height: 12px; border-radius: 50%; margin-right: 5px;"></div>
                  <span style="color: #7b91b0;">${seriesName}</span>
                </div>`;
      },
    },
  };

  // Chart data
  const chartSeries = [
    {
      name: "Energy Consumption",
      data: [totalConsumptionRenewable, totalConsumptionNonRenewable], // Renewable and Non-Renewable values
    },
  ];

  return (
    <div className="container" style={{ height: "100%" }}>
      <div style={{ fontSize: "18px", fontWeight: 600, height: "10%" }}>
        Renewable & Non-Renewable Energy Consumption (In GJ)
      </div>
      <div style={{ height: "90%" }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={"100%"}
        />
      </div>
    </div>
  );
};

export default EnergyConsumptionChart;

import { colors } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts'; // Import the ApexCharts component

const TotalWasteGeneratedMukt = ({ timePeriods, matchedDataWaste }) => {
  // Extract categories dynamically based on the specified condition
  const categories = matchedDataWaste.reduce((acc, item) => {
    if (item.question_details) {
      // Check for 'column1' first
      let filteredOptions = item.question_details
        .filter(detail => detail.option_type === 'column1')
        .map(detail => detail.option);

      // If no 'column1' found, check for 'column'
      if (filteredOptions.length === 0) {
        filteredOptions = item.question_details
          .filter(detail => detail.option_type === 'column')
          .map(detail => detail.option);
      }

      return acc.concat(filteredOptions);
    }
    return acc;
  }, []);

  // Remove duplicates
  const uniqueCategories = [...new Set(categories)];

  // Prepare data for each category (reversing axis logic)
  const seriesData = uniqueCategories.map((category, categoryIndex) => {
    const categoryData = Object.keys(timePeriods).map((timePeriod, timeIndex) => {
      // Find the corresponding object for this time period (H1, H2, etc.)
      const currentTimePeriodData = matchedDataWaste[timeIndex];
      
      if (currentTimePeriodData && currentTimePeriodData.question_details) {
        // Find the detail that matches the category
        const matchedDetail = currentTimePeriodData.question_details.find(
          detail => detail.option === category
        );
        // Extract the answer value from the correct answer array
        if (matchedDetail && currentTimePeriodData.answer && currentTimePeriodData.answer[0]) {
          const answerValue = currentTimePeriodData.answer[0][categoryIndex]; // Use the correct index for the category
          return answerValue !== undefined ? answerValue : 0;
        }
      }
      return 0; // Default to 0 if no data is found
    });

    return {
      name: category, // Each category will now be a series
      data: categoryData, // Data for each time period (H1, H2, etc.)
    };
  });

  // Chart options
  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: Object.keys(timePeriods), // Time periods will be on the x-axis
    },
    yaxis:{
      title: {
        text: 'Waste in mT', // Title for the Y-axis
        style: {
          fontSize: '14px', // You can customize font size here
          fontWeight: 'bold', // Customize font weight
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false, // Make the bars horizontal
        columnWidth: "25%",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "#C6CB8D",
      "#949776",
      "#ABC4B2",
      "#6D8B96",
      "#9CDFE3",
      "#11546f",
      "#587b87",
      "#8CBBCE",
    ],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 10, // Space between items
        vertical: 10, // Space between rows
      },
      markers: {
        width: 12,       // Marker width
        height: 12,      // Marker height
        radius: 12,      // Makes the markers circular
        offsetX: 0,      // Horizontal offset (optional)
        offsetY: 0,      // Vertical offset (optional)
      },
     
      // Set max rows for legend
      onItemClick: {
        toggleDataSeries: true, // Toggle visibility of series
      },
      itemStyle: {
        fontSize: '12px', // Adjust font size for legend items if needed
      },
    },
  };
  
  // Ensure to use this `chartOptions` in your chart component
  

  return (
    <div className="container">
      <div style={{ height: "10%", fontSize: "20px", fontWeight: 600, color: "#011627", marginBottom:"2%"}}>
      Total Waste Generated (in mT)      </div>
      <div style={{ height:"90%"}}>
      <Chart options={chartOptions} series={seriesData} type="bar" height={"100%"} />


      </div>
    </div>
  );
};

export default TotalWasteGeneratedMukt;

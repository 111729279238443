import React from "react";



const EnvironmentSingleLocMultipleTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  graphData,
  matchedDataWater,
  matchedWaterDis,
  brief,
  locationOption,
  keyTab,
  timePeriodValues,
}) => {
    
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "70%" }}>
         
          </div>

          
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
       
          </div>
          
        </div>
      </div>
   
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
    <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "60vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
       
        </div>

        <div style={{ height: "0%" }}>
      
        </div>
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
     
        </div>
        <div style={{ height: "0%" }}>
       
        </div>
      </div>
    </div>
    <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "60vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
       
        </div>

        <div style={{ height: "0%" }}>
      
        </div>
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
     
        </div>
        <div style={{ height: "0%" }}>
       
        </div>
      </div>
    </div>
   
  </div>
  );
};

export default EnvironmentSingleLocMultipleTime;

import React, { useEffect, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import ApexCharts from "react-apexcharts";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";

const FrameworkComponent = ({ fromDate, toDate, financialYearId, framework, timePeriods, locationOption }) => {
  const [filter, setFilter] = useState(false);
  const [stackedBarData, setStackedBarData] = useState([]);
  const [frameworkOptions, setFrameworkOptions] = useState([]);
  const [selectedFramework, setSelectedFramework] = useState([]);
  console.log(financialYearId, framework, timePeriods, locationOption, "sadasdadwedsaw")
  const handleFilterClose = () => setFilter(false);
  const fetchData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}frameworkProgress`,
      {},
      { fromDate: fromDate, toDate: toDate, financialYearId: financialYearId },
      "GET"
    );
 
    if (isSuccess) {
      setFrameworkOptions(data?.data?.frameworkOptions || []);
      const filteredFrameworks = framework.map(f => f.value);
      const filteredData = data?.data?.filter(f => filteredFrameworks.includes(f.frameWorkId)) || [];
      const formattedData = filteredData.map(f => ({
        name: f.frameworkOptions[0].title,
        data: [
          f.series[0], // answered
          f.series[1], // unanswered
          f.series[2], // accepted
          f.series[3]  // rejected
        ]
      }));
      setStackedBarData(formattedData);
    }
  };
  // const fetchData = async () => {
  //   const locationIds = locationOption.map(item => item.id);
  //   const frameworkIds = framework.filter(item => item.value !== "all").map(item => item.value);
  //   const periods = Object.values(timePeriods);
  //   const { isSuccess, data } = await apiCall(
  //     `${config.POSTLOGIN_API_URL_COMPANY}frameworkProgress`,
  //     {},
  //     { periods, financialYearId, frameworkIds, locationIds },
  //     "GET"
  //   );

  //   if (isSuccess) {
  //     setFrameworkOptions(data?.data?.frameworkOptions || []);
  //     const filteredFrameworks = framework.map(f => f.value);
  //     const filteredData = data?.data?.filter(f => filteredFrameworks.includes(f.frameWorkId)) || [];
  //     const formattedData = filteredData.map(f => ({
  //       name: f.frameworkOptions[0].title,
  //       data: [
  //         f.series[0], // answered
  //         f.series[1], // unanswered
  //         f.series[2], // accepted
  //         f.series[3]  // rejected
  //       ]
  //     }));
  //     setStackedBarData(formattedData);
  //   }
  // };

  useEffect(() => {
    if (framework) {
      fetchData();
    }
  }, [framework, fromDate, toDate, financialYearId]);

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: 'normal',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    xaxis: {
      categories: ['Answered', 'Unanswered', 'Accepted', 'Rejected'], // Set categories for x-axis
      title: {
        text: '', // Remove x-axis title
      },
      labels: {
        style: {
          colors: '#000', // Adjust label color if needed
        },
      },
    },
    yaxis: {
      title: {
        text: '', // Remove y-axis title
      },
      labels: {
        style: {
          colors: '#D3D3D3', // Light gray color for y-axis labels
        },
      },
    },
    legend: {
      position: 'bottom', // Place legend at the bottom
      horizontalAlign: 'center', // Center-align legend horizontally
      itemMargin: {

        horizontal: 30, // Add space between legend items
      },
      offsetY: 10, // Add margin-top (adjust as needed)
    },
    fill: {
      opacity: 1,
    },
    colors: ['#DDC272', '#AE6666', '#11546F', '#F27A7A'],
    dataLabels: {
      enabled: true, // Enable data labels for better visibility
    },
  };



  const chartSeries = stackedBarData.map(f => ({
    name: f.name,
    data: f.data
  }));


  return (
    <>
      <Col md={12} style={{ height: "100%" }}>
        <div className="bg-white framework" style={{ height: "100%" }}>
          <div className="frameworkHeader" style={{ height: "5%" }}>
            My Disclosure Progress
          </div>
          <div className="p-1" style={{ height: "95%" }}>
            {stackedBarData.length > 0 && (
              <ApexCharts
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={"100%"}
              />
            )}
          </div>
        </div>
      </Col>
      <Modal size="md" show={filter} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Form.Label className="align-items-center m-0">
            Framework Progress Filter
          </Form.Label>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formStatusType">
            <Form.Label>Select Framework Name</Form.Label>
            <Form.Select
              aria-label="Select Framework"
              className="w-100"
              value={selectedFramework}
              onChange={(e) => setSelectedFramework(e.target.value)}
            >
              {frameworkOptions.map((fw) => (
                <option key={fw.id} value={fw.id}>
                  {fw.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="new_button_style" onClick={() => handleFilterClose()}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FrameworkComponent;

// import React, { useState, useEffect } from "react";
// import { apiCall } from "../../../_services/apiCall";
// import config from "../../../config/config.json";
// import Chart from "react-apexcharts"; // Assuming you are using ApexCharts

// const ProductWiseFourtyEight = ({
//   timePeriods,
//   locationOption,
//   brief,
//   timePeriodValues,
// }) => {
//   console.log("brief", brief);
//   const [chartSeries, setChartSeries] = useState([]);
//   const [chartOptions, setChartOptions] = useState({
//     chart: {
//       type: "bar",
//       height: 350,
//       stacked: true, // Enable stacking
//     },
//     plotOptions: {
//       bar: {
//         borderRadius: 5,
//         horizontal: false,
//         columnWidth: "25%",
//         endingShape: "rounded",
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["#fff"],
//     },
//     xaxis: {
//       categories: [], // This will be set dynamically based on locationOption
//       title: {
//         text: locationOption.length > 1 ? "--------- Locations ---------" : timePeriodValues.length > 1 ? "---------- Time Period ----------" : "",
//       style: {
//         fontSize: '12px',    // Customize the font size
//         fontWeight: 400,  // Customize the font weight, e.g., 'bold', 'normal'
//         fontFamily: 'Arial', // Customize the font family
//         color: '#011627',       // Customize the font color

//       },
//     },
//       labels: {
//         style: {
//           colors: '#7b91b0',  // Light blue color for the y-axis labels
//           fontSize: '12px',   // Adjust the label font size if needed
//           fontFamily: 'Poppins',
//         },
//       },
//     },
//     yaxis: {
//         title: {
//           text: "Energy (GJ)",
//           style: {
//             fontSize: '12px',    // Customize the font size
//             fontWeight: 400,  // Customize the font weight, e.g., 'bold', 'normal'
//             fontFamily: 'Arial', // Customize the font family
//             color: '#011627',       // Customize the font color

//           },
//         },
//         logarithmic: false, // Set y-axis to logarithmic scale
//         labels: {
//           formatter: function (val) {
//             return Math.pow(10, val).toFixed(0); // Reverse log for display in axis labels
//           },
//           // Separate the style object from the formatter
//           style: {
//             colors: ['#7b91b0'],  // Light blue color for the y-axis labels
//             fontSize: '12px',     // Adjust the label font size if needed
//             fontFamily: 'Poppins',
//           },
//         },
//       },

//     fill: {
//       opacity: 1,
//     },
//     tooltip: {
//         y: {
//             formatter: function (val) {
//               const originalValue = Math.pow(10, val); // Reverse the log transformation
//               return `${originalValue.toFixed(2)} GJ`; // Show the original un-logged value in the tooltip
//             },
//           },
//     },
//     grid: {
//       borderColor: "#e7e7e7",
//       row: {
//         colors: ["transparent", "transparent"],
//         opacity: 0.5,
//       },
//     },
//     colors: [],
//   });

//   useEffect(() => {
//     const generateColors = (num) => {
//       // Function to generate a color palette for multiple products
//       const colors = [
//         "#83bbd5", "#f3a683", "#f7d794", "#786fa6", "#778beb",
//         "#f8a5c2", "#63cdda", "#ea8685", "#cf6a87", "#596275",
//         // Add more colors if needed
//       ];
//       return colors.slice(0, num); // Return as many colors as the number of products
//     };

//     let series = [];
//     let categories = [];
//     let originalData = [];

//     const logTransform = (value) => (value > 0 ? Math.log10(value) : 0); // Apply log transformation to positive values

//     if (locationOption.length > 1 && timePeriodValues.length === 1) {
//       if (brief && brief.time) {
//         categories = Object.keys(brief.time);
//         const productTotals = {};

//         Object.entries(brief.time).forEach(([locationKey, locationValue]) => {
//           Object.keys(locationValue).forEach((productKey) => {
//             if (!productTotals[productKey]) {
//               productTotals[productKey] = Array(categories.length).fill(0);
//             }
//             const categoryIndex = categories.indexOf(locationKey);
//             const valueArray = locationValue[productKey];
//             const value = Array.isArray(valueArray) ? valueArray[0] : 0;
//             productTotals[productKey][categoryIndex] += Number(value) || 0;
//           });
//         });

//         originalData = Object.entries(productTotals).map(([productKey, data]) => ({
//           name: productKey,
//           data,
//         }));

//         series = originalData.map(({ name, data }) => ({
//           name,
//           data: data.map(logTransform), // Log-transform data for chart
//         }));

//         setChartSeries(series);
//         setChartOptions((prev) => ({
//           ...prev,
//           xaxis: {
//             ...prev.xaxis,
//             categories,
//           },
//           colors: generateColors(series.length), // Update colors dynamically
//         }));
//       }
//     } else if (locationOption.length === 1 && timePeriodValues.length > 1) {
//       if (brief && brief.location) {
//         categories = Object.keys(brief.location);
//         const productTotals = {};

//         Object.entries(brief.location).forEach(([locationKey, locationValue]) => {
//           Object.keys(locationValue).forEach((productKey) => {
//             if (!productTotals[productKey]) {
//               productTotals[productKey] = Array(categories.length).fill(0);
//             }
//             const categoryIndex = categories.indexOf(locationKey);
//             const valueArray = locationValue[productKey];
//             const value = Array.isArray(valueArray) ? valueArray[0] : 0;
//             productTotals[productKey][categoryIndex] += Number(value) || 0;
//           });
//         });

//         originalData = Object.entries(productTotals).map(([productKey, data]) => ({
//           name: productKey,
//           data,
//         }));

//         series = originalData.map(({ name, data }) => ({
//           name,
//           data: data.map(logTransform), // Log-transform data for chart
//         }));

//         setChartSeries(series);
//         setChartOptions((prev) => ({
//           ...prev,
//           xaxis: {
//             ...prev.xaxis,
//             categories,
//           },
//           colors: generateColors(series.length), // Update colors dynamically
//         }));
//       }
//     } else {
//       if (brief && brief.location) {
//         categories = Object.keys(brief.location);
//         const productTotals = {};

//         Object.entries(brief.location).forEach(([locationKey, locationValue]) => {
//           Object.keys(locationValue).forEach((productKey) => {
//             if (!productTotals[productKey]) {
//               productTotals[productKey] = Array(categories.length).fill(0);
//             }
//             const categoryIndex = categories.indexOf(locationKey);
//             const valueArray = locationValue[productKey];
//             const valueSum = Array.isArray(valueArray)
//               ? valueArray.reduce((sum, val) => sum + Number(val) || 0, 0)
//               : 0;
//             productTotals[productKey][categoryIndex] += valueSum;
//           });
//         });

//         originalData = Object.entries(productTotals).map(([productKey, data]) => ({
//           name: productKey,
//           data,
//         }));

//         series = originalData.map(({ name, data }) => ({
//           name,
//           data: data.map(logTransform), // Log-transform data for chart
//         }));

//         setChartSeries(series);
//         setChartOptions((prev) => ({
//           ...prev,
//           xaxis: {
//             ...prev.xaxis,
//             categories,
//           },
//           colors: generateColors(series.length), // Update colors dynamically
//         }));
//       }
//     }
//   }, [locationOption, timePeriodValues, brief]);

//   return (
//     <div className="container" style={{ height: "100%" }}>
//       <div style={{ height: "10%", fontSize:"20px", fontWeight:600,fontColor:"#011627" }}>
//         Renewable & Non-Renewable Energy Consumption (In GJ)
//       </div>
//       <div style={{ height: "90%" }}>
//         <Chart
//           options={chartOptions}
//           series={chartSeries}
//           type="bar"
//           height={"100%"}
//         />
//       </div>
//     </div>
//   );
// };

// export default ProductWiseFourtyEight;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import Chart from "react-apexcharts"; // Assuming you are using ApexCharts
import Select, { components } from "react-select"; // Importing React Select

const ProductWiseFourtyEight = ({
  timePeriods,
  locationOption,
  brief,
  timePeriodValues,
}) => {
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      stacked: true, // Enable stacking
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        const logValue = val; // Logarithmic value passed to tooltip
        const originalValue = Math.pow(10, logValue); // Reverse log to get original value
        return `${originalValue.toFixed(0)} GJ`; // Display original value in tooltip
      },
      offsetY: 0, // Center the label vertically inside the bar
      style: {
        fontSize: "8px",
        colors: ["#fff"], // White text inside bars
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    xaxis: {
      categories: [], // This will be set dynamically based on selected products
    
      labels: {
        style: {
          colors: "#7b91b0",
          fontSize: "12px",
          fontFamily: "Poppins",
        },
      },
    },
    yaxis: {
      title: {
        text: "---------- Energy in GJ -----------",
        style: {
          fontSize: "12px",
          fontWeight: 400,
          fontFamily: "Arial",
          color: "#011627",
        },
      },

      labels: {
        style: {
          colors: ["#7b91b0"],
          fontSize: "12px",
          fontFamily: "Poppins",
        },
        formatter: (value) => value.toFixed(1), // Removes decimals
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          const unloggedValue = Math.pow(10, val);

          // Format and display the original (unlogged) value
          return `${unloggedValue.toFixed(2)} GJ`;
        },
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
    colors: [],
    legend: {
      show: true,
      markers: {
        width: 0, // Hides default legend markers (squares)
        height: 0,
      },
      position: "bottom", // Adjust as necessary (top, right, bottom, left)
      horizontalAlign: "center", // Align legend items in the center
      itemMargin: {
        horizontal: 10, // Space between legend items
        vertical: 0, // Vertical space (if needed)
      },
      formatter: function (seriesName, opts) {
        return `<div style="display: flex; align-items: center;">
                  <div style="background-color: ${
                    opts.w.config.colors[opts.seriesIndex]
                  }; width: 12px; height: 12px; border-radius: 50%; margin-right: 5px;"></div>
                  <span style="color: #7b91b0;">${seriesName}</span>
                </div>`;
      },
    },
  });

  // State for product selection
  const [selectedProducts, setSelectedProducts] = useState([]);

  const generateColors = (num) => {
    const colors = [
      "#83bbd5",
      "#f3a683",
      "#f7d794",
      "#786fa6",
      "#778beb",
      "#f8a5c2",
      "#63cdda",
      "#ea8685",
      "#cf6a87",
      "#596275",
      // Add more colors if needed
    ];
    return colors.slice(0, num); // Return as many colors as the number of products
  };

  useEffect(() => {
    const logTransform = (value) => (value > 0 ? Math.log10(value) : 0); // Apply log transformation to positive values

    if (timePeriodValues.length > 1 && locationOption.length == 1) {
      if (brief && brief.location) {
        const productTotals = {};
        const categories = Object.keys(brief.location);

        // Aggregate data based on selected products
        Object.entries(brief.location).forEach(
          ([locationKey, locationValue]) => {
            Object.keys(locationValue).forEach((productKey) => {
              if (
                selectedProducts.length === 0 ||
                selectedProducts.includes(productKey)
              ) {
                if (!productTotals[productKey]) {
                  productTotals[productKey] = Array(categories.length).fill(0);
                }
                const categoryIndex = categories.indexOf(locationKey);
                const valueArray = locationValue[productKey];
                const valueSum = Array.isArray(valueArray)
                  ? valueArray.reduce((sum, val) => sum + Number(val) || 0, 0)
                  : 0;
                productTotals[productKey][categoryIndex] += valueSum;
              }
            });
          }
        );

        const originalData = Object.entries(productTotals).map(
          ([productKey, data]) => ({
            name: productKey,
            data,
          })
        );

        const series = originalData.map(({ name, data }) => ({
          name,
          data: data.map(logTransform), // Log-transform data for chart
        }));

        setChartSeries(series);
        setChartOptions((prev) => ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            categories,
          },
          colors: generateColors(series.length), // Update colors dynamically
        }));
      }
    }
    else if (timePeriodValues.length == 1 && locationOption.length > 1){
      if (brief && brief.time) {
        const productTotals = {};
        const categories = Object.keys(brief.time);

        // Aggregate data based on selected products
        Object.entries(brief.time).forEach(
          ([locationKey, locationValue]) => {
            Object.keys(locationValue).forEach((productKey) => {
              if (
                selectedProducts.length === 0 ||
                selectedProducts.includes(productKey)
              ) {
                if (!productTotals[productKey]) {
                  productTotals[productKey] = Array(categories.length).fill(0);
                }
                const categoryIndex = categories.indexOf(locationKey);
                const valueArray = locationValue[productKey];
                const valueSum = Array.isArray(valueArray)
                  ? valueArray.reduce((sum, val) => sum + Number(val) || 0, 0)
                  : 0;
                productTotals[productKey][categoryIndex] += valueSum;
              }
            });
          }
        );

        const originalData = Object.entries(productTotals).map(
          ([productKey, data]) => ({
            name: productKey,
            data,
          })
        );

        const series = originalData.map(({ name, data }) => ({
          name,
          data: data.map(logTransform), // Log-transform data for chart
        }));

        setChartSeries(series);
        setChartOptions((prev) => ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            categories,
          },
          colors: generateColors(series.length), // Update colors dynamically
        }));
      }
    }
    else{
      if (brief && brief.location) {
        const productTotals = {};
        const categories = Object.keys(brief.location);

        // Aggregate data based on selected products
        Object.entries(brief.location).forEach(
          ([locationKey, locationValue]) => {
            Object.keys(locationValue).forEach((productKey) => {
              if (
                selectedProducts.length === 0 ||
                selectedProducts.includes(productKey)
              ) {
                if (!productTotals[productKey]) {
                  productTotals[productKey] = Array(categories.length).fill(0);
                }
                const categoryIndex = categories.indexOf(locationKey);
                const valueArray = locationValue[productKey];
                const valueSum = Array.isArray(valueArray)
                  ? valueArray.reduce((sum, val) => sum + Number(val) || 0, 0)
                  : 0;
                productTotals[productKey][categoryIndex] += valueSum;
              }
            });
          }
        );

        const originalData = Object.entries(productTotals).map(
          ([productKey, data]) => ({
            name: productKey,
            data,
          })
        );

        const series = originalData.map(({ name, data }) => ({
          name,
          data: data.map(logTransform), // Log-transform data for chart
        }));

        setChartSeries(series);
        setChartOptions((prev) => ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            categories,
          },
          colors: generateColors(series.length), // Update colors dynamically
        }));
      }
    }
  }, [selectedProducts, brief]); // Added selectedProducts as a dependency

  // Options for product selection dropdown
  const productOptions = useMemo(() => {
    if (brief?.location && Object.keys(brief.location).length > 0) {
      const firstKey = Object.keys(brief.location)[0];
      return Object.keys(brief.location[firstKey] || {}).map((product) => ({
        value: product,
        label: product,
      }));
    }
    return [];
  }, [brief]);

  // Handle product selection
  const handleProductChange = useCallback((selectedOptions) => {
    if (selectedOptions.length > 5) {
      alert("You can only select up to 5 products.");
      return;
    }
    setSelectedProducts(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  }, []);

  // Set initial selection of first 5 products if available
  useEffect(() => {
    if (productOptions.length > 0) {
      setSelectedProducts(
        productOptions.slice(0, 5).map((option) => option.value)
      );
    }
  }, [productOptions]);

  const CustomOption = (props) => {
    const { isSelected, data } = props;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            style={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                border: "2px solid #3f88a5",
                borderRadius: "2px",
                backgroundColor: isSelected ? "transparent" : "transparent",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Tick mark when selected */}
              {isSelected && (
                <span style={{ color: "white", fontSize: "14px" }}>✔</span>
              )}
            </div>
          </div>

          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div style={{ fontSize: "14px", fontWeight: 300 }}>
              {data.label}
            </div>
          </div>
        </div>
      </components.Option>
    );
  };

  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value, placeholder } = selectProps;

    return (
      <components.Control {...props}>
        {/* Placeholder or selected value */}
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          >
            {placeholder}
          </div>
        )}
        {/* Display only the first selected product */}
        {value && value.length > 0 && (
          <div
            style={{
              color: "#3f88a5",
              marginLeft: "5px",
              fontSize: "12px",
              width: "55%",
            }}
          >
            {value[0].label}
          </div>
        )}

        {/* Ensure you still render the child components like the dropdown indicator and input */}
        {props.children}
      </components.Control>
    );
  };

  const CustomClearIndicator = () => null; 
  return (
    <div className="container" style={{ height: "100%" }}>
      <div
        style={{
          height: "10%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: 600,
            color: "#011627",
            width: "50%",
          }}
        >
          Renewable & Non-Renewable Energy Consumption (In GJ)
        </div>
        <div style={{ width: "45%" }}>
          <Select
            isMulti
            options={productOptions}
            onChange={handleProductChange}
            value={productOptions.filter((option) =>
              selectedProducts.includes(option.value)
            )} // Set selected options
            placeholder="Select Products"
            hideSelectedOptions={false} // Keep selected options in the dropdown
            className=""
            components={{
              Option: CustomOption,
              Control: CustomControl,
              MultiValue: CustomMultiValue,
              ClearIndicator:CustomClearIndicator
            }}
            closeMenuOnSelect={false} // Prevent dropdown from closing
            styles={{
              control: (base) => ({
                ...base,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 100, // Ensure the menu appears above other elements
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#3f88a5", // Change color of the dropdown arrow
                padding: "0 10px", // Adjust padding for the indicator
                fontSize: "20px", // Increase the font size of the indicator
                minHeight: "20px", // Set a minimum height for the indicator
                minWidth: "20px", // Set a minimum width for the indicator
              }),
              placeholder: (base) => ({
                ...base,
                position: "absolute", // Ensure the placeholder doesn't shift with input
                top: "50%",
                transform: "translateY(-50%)", // Vertically center the placeholder
                pointerEvents: "none", // Disable interaction on the placeholder
              }),
              multiValue: (base) => ({
                ...base,
                background: "transparent",
                border: "2px solid #3f88a5",
                borderRadius: "10px",
                marginTop: "0.5em",
              }),
              option: (provided, state) => ({
                ...provided,

                backgroundColor: state.isSelected
                  ? "transparent" // Selected option background color
                  : state.isFocused
                  ? "white" // Focused option background color
                  : "white", // Default option background color
                color: state.isSelected ? "black" : "black", // Text color based on state
                cursor: "pointer",
              }),
            }}
          />
        </div>
      </div>

      <div style={{ height: "90%" }}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={"100%"}
        />
      </div>
    </div>
  );
};

export default ProductWiseFourtyEight;

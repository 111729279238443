import React from 'react';

const TotalWasteGeneratedByTpe = ({title, timePeriods, timePeriodValues, locationOption, matchedDataWaste }) => {
  // Extract categories dynamically based on the specified condition
  const categories = matchedDataWaste.reduce((acc, item) => {
    if (item.question_details) {
        // Check for 'column1' first
        let filteredOptions = item.question_details
          .filter(detail => detail.option_type === 'column1')
          .map(detail => detail.option);
      
        // If no 'column1' found, check for 'column'
        if (filteredOptions.length === 0) {
          filteredOptions = item.question_details
            .filter(detail => detail.option_type === 'column')
            .map(detail => detail.option);
        }
      
        return acc.concat(filteredOptions);
      }
      
    return acc;
  }, []);

  // Remove duplicates
  const uniqueCategories = [...new Set(categories)];

  // Map the categories to their corresponding answer values using the first array in answer
  const categoryValues = uniqueCategories.map((category, categoryIndex) => {
    const totalValue = matchedDataWaste.reduce((sum, item) => {
      if (item.question_details && item.answer && item.answer[0]) { // Ensure the first array in answer exists
        const matchedDetail = item.question_details.find(detail => detail.option === category);
        const answerValue = item.answer[0][categoryIndex]; // Use the first array in answer
        if (matchedDetail && answerValue !== undefined) {
          return sum + Number(answerValue); // Sum values for each category
        }
      }
      return sum;
    }, 0);
    
    return {
      category,
      totalValue,
    };
  });

  const totalSum = categoryValues.reduce((sum, item) => sum + Number(item.totalValue), 0);
  const logTotalSum = categoryValues.reduce((sum, item) => sum + Math.log(item.totalValue + 1), 0);


  // Colors for each category
  const colors = ['#C6CB8D', '#949776', '#ABC4B2', '#6D8B96', '#9CDFE3', '#11546f', '#587b87', '#8CBBCE'];

  return (
    <div  className="container">
     <div className="renewable-bar-header">
        {title}
      </div>
      <div className="renewable-bar-labels">
        <span style={{ fontSize: "11px" }}>0</span>
        <span style={{ fontSize: "11px" }}>
          {Math.round(totalSum / 5 / 10) * 10}
        </span>
        <span style={{ fontSize: "11px" }}>
          {Math.round(((totalSum / 5) * 2) / 10) * 10}
        </span>
        <span style={{ fontSize: "11px" }}>
          {Math.round(((totalSum / 5) * 3) / 10) * 10}
        </span>
        <span style={{ fontSize: "11px" }}>
          {Math.round(((totalSum / 5) * 4) / 10) * 10}
        </span>
        <span style={{ fontSize: "11px" }}>
          {Math.round(totalSum / 10) * 10}
        </span>
      </div>
      <div className="renewable-bar-dotted-line"></div>

      {categoryValues.length > 0 && totalSum > 0 ? (
       <div>
       {/* The bar chart */}
       <div style={{ display: 'flex', width: '100%', height: '30px', border: '1px solid #ccc' }}>
         {categoryValues.map((item, index) => {

if (Number(item.totalValue) === 0) return null;
           // Use log values for the width calculation
           const logValue = Math.log(item.totalValue + 1); // log(1) to avoid log(0)
           const widthPercentage = (logValue / logTotalSum) * 100;
 
           return (
             <div
               key={index}
               style={{
                 width: `${widthPercentage}%`, // Use logarithmic width
                 backgroundColor: colors[index % colors.length],
                 position: 'relative', // For absolute positioning the true value text
               }}
               title={`${item.category}: ${Number(item.totalValue)}`} // Tooltip with the true value
             >
               {/* Show the true value in the middle of the bar */}
               <span style={{
                 position: 'absolute',
                 top: '50%',
                 left: '50%',
                 transform: 'translate(-50%, -50%)',
                 fontSize: '12px',
                 color: '#fff',
               }}>
                 {item.totalValue}
               </span>
             </div>
           );
         })}
       </div>
 
       {/* The legend */}
       <div style={{ display: 'flex', marginTop: '10px', width: '100%', overflow: 'auto' }}>
         {categoryValues.map((item, index) => (
           <div key={index} style={{ width: '20%', display: 'flex', alignItems: 'center', marginRight: '15px' }}>
             <div style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
               <div
                 style={{
                   width: '15px',
                   height: '15px',
                   borderRadius: '50%',
                   backgroundColor: colors[index % colors.length],
                   marginRight: '5px',
                 }}
               />
             </div>
             <div style={{ width: '70%' }}>
               <div style={{ fontSize: '12px' }}>{item.category}</div>
             </div>
           </div>
         ))}
       </div>
     </div>
      ) : (
        <p>No categories available for the selected options.</p>
      )}
    </div>
  );
};

export default TotalWasteGeneratedByTpe;

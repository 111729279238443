import React, { useEffect, useState } from "react";

const BarComponentWater = ({ matchedDataWater , title}) => {
  const [rowOptionsWithValues, setRowOptionsWithValues] = useState([]);
  const colors = [
    "#C1DDEA",
    "#DEEFF8",
    "#3ABEC7",
    "#3F88A5",
    "#88D29E",
    "#11546F",
  ]; // Colors for different water types

  useEffect(() => {
    if (matchedDataWater && matchedDataWater.length > 0) {
      matchedDataWater.forEach((data) => {
        const extractedRowOptions = data.question_details
          .filter((detail) => detail.option_type === "row") // Filter for 'row' type
          .map((detail) => detail.option); // Extract 'option'

        const answerValues = data.answer.map((answerArr) =>
          Number(answerArr[0])
        ); // Extract first element from each inner array

        // Pair each rowOption with its corresponding value from answer
        const combined = extractedRowOptions
          .slice(1)
          .reverse()
          .map((option, index) => ({
            option,
            value: answerValues[index] || 0, // Default to 'N/A' if no corresponding value
          }));

        setRowOptionsWithValues(combined);
      });
    }
  }, [matchedDataWater]);

  console.log("Row Options with Values:", rowOptionsWithValues);

  const totalSum = rowOptionsWithValues.reduce(
    (sum, item) => sum + item.value,
    0
  );

  return (
    <div className="container">
     <div style={{ fontSize: "18px", fontWeight: 600, height: "10%" }}>
      {title}
      </div>

    {/* Bar Labels Section */}
    <div className="renewable-bar-labels" style={{ marginTop: "5%" }}>
      <span style={{ fontSize: "11px" }}>0</span>
      <span style={{ fontSize: "11px" }}>{Math.round(totalSum / 5)}</span>
      <span style={{ fontSize: "11px" }}>{Math.round((totalSum / 5) * 2)}</span>
      <span style={{ fontSize: "11px" }}>{Math.round((totalSum / 5) * 3)}</span>
      <span style={{ fontSize: "11px" }}>{Math.round((totalSum / 5) * 4)}</span>
      <span style={{ fontSize: "11px" }}>{Math.round(totalSum)}</span>
    </div>

    {/* Dotted Line for the Bar */}
    <div className="renewable-bar-dotted-line" style={{ height: '1px', background: 'dotted', marginBottom: '10px' }}></div>

    {rowOptionsWithValues.length > 0 ? (
      <>
        {/* Bar Section */}
        <div
          style={{
            display: 'flex',
            height: '30px',
            width: '100%',
            backgroundColor: '#e0e0e0',
            overflow: 'hidden',
            marginBottom: '20px'
          }}
        >
          {rowOptionsWithValues.map((item, index) => {
            const widthPercentage = (item.value / totalSum) * 100;
            return (
              <div
                key={index}
                style={{
                  width: `${widthPercentage}%`,
                  backgroundColor: colors[index % colors.length],
                  display: item.value > 0 ? "flex" : "none", // Show only if value > 0
                  height: "100%",
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                  color: "white", // White text
                  fontWeight: "bold", // Bold text
                  fontSize: "12px", // Adjust the size if needed
                }}
                title={`${item.option}: ${item.value}`} // Show value on hover
              >
                {item.value} {/* Display the value in the middle */}
              </div>
            );
          })}
        </div>

        {/* Legend Section */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {rowOptionsWithValues.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  width: "15px",
                  borderRadius:"50%",
                  height: "15px",
                  backgroundColor: colors[index % colors.length],
                  marginRight: "5px",
                }}
              />
              <span style={{fontSize:"14px"}}>{item.option}</span>
            </div>
          ))}
        </div>
      </>
    ) : (
      <p>No data available</p>
    )}
  </div>
  );
};

export default BarComponentWater;

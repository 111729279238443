import React from "react";


const EnvironmentSingleLocSingleTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  brief,
  graphData,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  locationOption,
  renewableEnergy,
  nonRenewableEnergy,
  keyTab,
  timePeriodValues,
 
}) => {
  return companyFramework.includes(1) ? 
  (
    <div className="d-flex flex-column flex-space-between">
        <div  className="d-flex flex-row flex-space-between"
        style={{ height: "40vh", marginBottom: "3%" }}>
            

        </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh", marginBottom: "3%" }}
      >
        <div className="firsthalfprogressenergy" style={{display:"flex", flexDirection:"column", justifyContent:"space-between", width: "45%" }}>
            <div style={{height:"20%"}}>

            </div>

            <div style={{height:"75%"}}>

                
                </div>
       
         
        </div>
        <div className="secondhalfprogress" style={{display:"flex", flexDirection:"column", justifyContent:"space-between",width:"55%"}}>
          <div style={{ height: "45%" }}>
          
       
          </div>
          <div style={{ height: "40%" }}>
            
        
          </div>
        </div>
      </div>
      {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
      
      </div> */}
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "27.5vh", marginBottom: "3%" }}
      >
        <div className="firsthalfprogressenergy" style={{display:"flex", flexDirection:"column", justifyContent:"space-between", width: "45%" }}>
            <div style={{height:"65%"}}>

            </div>

            <div style={{height:"0%"}}>
                {/* <EnergyConsumptionChart totalConsumptionRenewable={totalConsumptionRenewable} totalConsumptionNonRenewable={totalConsumptionNonRenewable} /> */}

                
                </div>
       
         
        </div>
        <div className="secondhalfprogress" style={{display:"flex", flexDirection:"column", justifyContent:"space-between",width:"55%"}}>
          <div style={{ height: "100%" }}>
       
          </div>
          <div style={{ height: "0%" }}>
            {/* <CommonBarComponent
            renewableEnergy={renewableEnergy}
            nonRenewableEnergy={nonRenewableEnergy}
            com={"non"}
/> */}
        
          </div>
        </div>
      </div>
      {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
    
      </div> */}
    </div>


    </>
  );
};

export default EnvironmentSingleLocSingleTime;

// import React, { useState, useEffect } from "react";

// const CommonBarComponent = ({ renewableEnergy, nonRenewableEnergy, com }) => {
//   let recoverySeries = ["Re-cycled", "Re-used", "Other Recovery Options"];
//   let colors = ["#83bbd5", "#11546F", "#65b1b6", "#4a849f", "#86caea"]; // Define colors for each category

//   if (com == "non") {
//     const firstObject = nonRenewableEnergy[0];

//     // Extract all the rows but skip the first one
//     recoverySeries = firstObject.question_details
//       .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
//       .slice(1) // Skip the first row
//       .map((detail) => detail.option)
//       .reverse(); // Extract the "option" values
//   } else {
//     const firstObject = renewableEnergy[0];

//     // Extract all the rows but skip the first one
//     recoverySeries = firstObject.question_details
//       .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
//       .slice(1) // Skip the first row
//       .map((detail) => detail.option)
//       .reverse(); // Extract the "option" values
//   }

//   const [aggregatedData, setAggregatedData] = useState({});
//   const [totalSum, setTotalSum] = useState(0);

//   useEffect(() => {
//     // Initialize an object to store the aggregated values
//     const aggregated = recoverySeries.reduce((acc, recoveryType) => {
//       acc[recoveryType] = 0; // Initialize each recovery type with 0
//       return acc;
//     }, {});

//     if (com == "non") {
//       nonRenewableEnergy.forEach((item) => {
//         const answers =
//           item.answer && Array.isArray(item.answer) ? item.answer : [];

//         answers.forEach((answerArray, index) => {
//           // Ensure answerArray is an array and contains values
//           if (
//             Array.isArray(answerArray) &&
//             answerArray.length > 0 &&
//             recoverySeries[index]
//           ) {
//             const value = answerArray[0]; // First element corresponds to recoverySeries value

//             // Apply conditions to check and parse value
//             const numericValue =
//               value === "NA" ||
//               value === "No" ||
//               value === "Yes" ||
//               value === "" ||
//               value === "KWH" ||
//               !value
//                 ? 0
//                 : parseFloat(value);

//             // Aggregate value into the correct recovery series
//             aggregated[recoverySeries[index]] += numericValue;
//           }
//         });
//       });
//     } else {
//       renewableEnergy.forEach((item) => {
//         const answers =
//           item.answer && Array.isArray(item.answer) ? item.answer : [];

//         answers.forEach((answerArray, index) => {
//           // Ensure answerArray is an array and contains values
//           if (
//             Array.isArray(answerArray) &&
//             answerArray.length > 0 &&
//             recoverySeries[index]
//           ) {
//             const value = answerArray[0]; // First element corresponds to recoverySeries value

//             // Apply conditions to check and parse value
//             const numericValue =
//               value === "NA" ||
//               value === "No" ||
//               value === "Yes" ||
//               value === "" ||
//               value === "KWH" ||
//               !value
//                 ? 0
//                 : parseFloat(value);

//             // Aggregate value into the correct recovery series
//             aggregated[recoverySeries[index]] += numericValue;
//           }
//         });
//       });
//     }

//     // Calculate total sum
//     const sum = Object.values(aggregated).reduce(
//       (total, value) => total + value,
//       0
//     );

//     setAggregatedData(aggregated);
//     setTotalSum(sum);
//   }, [renewableEnergy, nonRenewableEnergy]);

//   const chunkArray = (arr, chunkSize) => {
//     const chunks = [];
//     for (let i = 0; i < arr.length; i += chunkSize) {
//       chunks.push(arr.slice(i, i + chunkSize));
//     }
//     return chunks;
//   };

//   const legendChunks = chunkArray(recoverySeries, 4);

//   return (
//     <div className="renewable-bar-container">
//       <div className="renewable-bar-header">
//         {com === "non"
//           ? "Non Renewable Energy Consumption Product Wise (In GJ)"
//           : "Renewable Energy Consumption Product Wise (In GJ)"}
//       </div>
//       <div className="renewable-bar-labels">
//         <span style={{ fontSize: "11px" }}>0</span>
//         <span style={{ fontSize: "11px" }}>{totalSum / 5}</span>
//         <span style={{ fontSize: "11px" }}>{(totalSum / 5) * 2}</span>
//         <span style={{ fontSize: "11px" }}>{(totalSum / 5) * 3}</span>
//         <span style={{ fontSize: "11px" }}>{(totalSum / 5) * 4}</span>
//         <span style={{ fontSize: "11px" }}>{totalSum}</span>
//       </div>
//       <div className="renewable-bar-dotted-line"></div>

//       <div className="renewable-bar">
//         {recoverySeries.map((recoveryType, index) => {
//           const value = aggregatedData[recoveryType] || 0;
//           const widthPercentage = totalSum > 0 ? (value / totalSum) * 100 : 0;
//           const barColor = colors[index % colors.length]; // Use color based on index

//           return (
//             <div
//               className="renewable-bar-section"
//               key={index}
//               title={`${recoveryType}: ${value.toFixed(2)} mT`}
//               style={{
//                 width: `${widthPercentage}%`,
//                 backgroundColor: barColor,
//                 display: "inline-block",
//                 fontSize: "12px",
//                 color: "white",
//                 height: "100%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               {value}
//             </div>
//           );
//         })}
//       </div>
//       <div className="renewable-legends-container" style={{ width: "100%", marginTop: "5%" }}>
//     {legendChunks.map((chunk, chunkIndex) => (
//       <div key={chunkIndex} className="renewable-legends" style={{ display: "flex", gap: "20px", width: "100%", marginBottom: "20px" }}>
//         {chunk.map((recoveryType, index) => {
//           const barColor = colors[(chunkIndex * 4 + index) % colors.length]; // Calculate color based on index
//           const value = aggregatedData[recoveryType] || 0;

//           return (
//             <div
//               className="renewable-legend"
//               key={index}
//               style={{ display: "flex", width: "25%" }} // 4 items in one row, so 25% width for each
//             >
//               <div
//                 className="renewable-legend-color"
//                 style={{
//                   display: "inline-block",
//                   width: "20px",
//                   height: "20px",
//                   borderRadius: "50%",
//                   backgroundColor: barColor,
//                   marginRight: "10px",
//                 }}
//               />
//               <div style={{ fontSize: "12px" }}>{recoveryType}</div>
//             </div>
//           );
//         })}
//       </div>
//     ))}
//   </div>

//     </div>
//   );
// };

// export default CommonBarComponent;

import React, { useState, useMemo, useEffect } from "react";
import Select, { components } from "react-select"; // Import React Select

const CommonBarComponent = ({ renewableEnergy, nonRenewableEnergy, com }) => {
  const initialRecoverySeries = [
    "Re-cycled",
    "Re-used",
    "Other Recovery Options",
  ];
  const colors = ["#83bbd5", "#11546F", "#65b1b6", "#4a849f", "#86caea"]; // Define colors for each category

  const [selectedProducts, setSelectedProducts] = useState([]);

  const recoverySeries = useMemo(() => {
    if (com === "non") {
      return nonRenewableEnergy[0]?.question_details
        .filter((detail) => detail.option_type === "row")
        .slice(1)
        .map((detail) => detail.option)
        .reverse();
    } else {
      return renewableEnergy[0]?.question_details
        .filter((detail) => detail.option_type === "row")
        .slice(1)
        .map((detail) => detail.option)
        .reverse();
    }
  }, [com, nonRenewableEnergy, renewableEnergy]);

  const productOptions = useMemo(() => {
    return recoverySeries.map((product) => ({
      value: product,
      label: product,
    }));
  }, [recoverySeries]);

  // Set selectedProducts to the first 5 options
  useEffect(() => {
    if (productOptions.length > 0) {
      const firstFiveProducts = productOptions.slice(0, 5);
      const productLabels = firstFiveProducts.map((product) => product.label);
      setSelectedProducts(productLabels);
    }
  }, [productOptions]);
  const [aggregatedData, setAggregatedData] = useState({});
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    if (selectedProducts) {
      const aggregated = selectedProducts.reduce((acc, recoveryType) => {
        acc[recoveryType] = 0; // Initialize each recovery type with 0
        return acc;
      }, {});

      const dataSource = com === "non" ? nonRenewableEnergy : renewableEnergy;

      dataSource.forEach((item) => {
        const answers =
          item.answer && Array.isArray(item.answer) ? item.answer : [];

        answers.forEach((answerArray, index) => {
          if (Array.isArray(answerArray) && answerArray.length > 0) {
            const recoveryType = recoverySeries[index];
            const value = answerArray[0]; // First element corresponds to recoverySeries value

            // Apply conditions to check and parse value
            const numericValue =
              value === "NA" ||
              value === "No" ||
              value === "Yes" ||
              value === "" ||
              value === "KWH" ||
              !value
                ? 0
                : parseFloat(value);

            // Only aggregate if the recoveryType is in selectedProducts
            if (selectedProducts.includes(recoveryType)) {
              aggregated[recoveryType] += numericValue;
            }
          }
        });
      });

      const sum = Object.values(aggregated).reduce(
        (total, value) => total + value,
        0
      );

      setAggregatedData(aggregated);
      setTotalSum(sum);
    }
  }, [selectedProducts]);

  const handleProductChange = (selectedOptions) => {
    if (selectedOptions.length > 5) {
      alert("You can only select up to 5 products.");
      return;
    }

    const selectedProductsArray = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    console.log("Selected Products:", selectedProductsArray); // Log the selected products

    setSelectedProducts(selectedProductsArray);
  };

  const CustomOption = (props) => {
    const { isSelected, data } = props;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Square Box */}
          <div
            style={{
              width: "20px",
              height: "20px",
              border: "2px solid #3f88a5",
              borderRadius: "2px",
              backgroundColor: isSelected ? "transparent" : "transparent",
              marginRight: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Tick mark when selected */}
            {isSelected && (
              <span style={{ color: "white", fontSize: "14px" }}>✔</span>
            )}
          </div>
          {/* Option Label */}
          <span style={{ fontSize: "14px", fontWeight: 300 }}>
            {data.label}
          </span>
        </div>
      </components.Option>
    );
  };

  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value, placeholder } = selectProps;

    return (
      <components.Control {...props}>
        {/* Placeholder or selected value */}
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          >
            {placeholder}
          </div>
        )}
        {/* Display only the first selected product */}
        {value && value.length > 0 && (
          <div style={{ color: "#3f88a5", marginLeft: "5px" }}>
            {value[0].label}
          </div>
        )}

        {/* Ensure you still render the child components like the dropdown indicator and input */}
        {props.children}
      </components.Control>
    );
  };

  return (
    <div className="renewable-bar-container" style={{ height: "100%" }}>
      <div
        className="renewable-bar-header"
        style={{
          height: "10%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {com === "non" ? (
          <div style={{ width: "40%" }}>
            {" "}
            Non Renewable Energy Consumption Product Wise (In GJ)
          </div>
        ) : (
          <div style={{ width: "40%" }}>
            {" "}
            Renewable Energy Consumption Product Wise (In GJ)
          </div>
        )}
        <div style={{ width: "40%" }}>
          <Select
            options={productOptions}
            onChange={handleProductChange}
            isMulti
            value={productOptions.filter((option) =>
              (selectedProducts || []).includes(option.value)
            )}
            placeholder={`Select products`}
            hideSelectedOptions={false} // Keep selected options in the dropdown
            className=""
            components={{
              Option: CustomOption,
              Control: CustomControl,
              MultiValue: CustomMultiValue,
            }}
            closeMenuOnSelect={false} // Prevent dropdown from closing
            styles={{
              control: (base) => ({
                ...base,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 100, // Ensure the menu appears above other elements
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#3f88a5", // Change color of the dropdown arrow
                padding: "0 10px", // Adjust padding for the indicator
                fontSize: "20px", // Increase the font size of the indicator
                minHeight: "20px", // Set a minimum height for the indicator
                minWidth: "20px", // Set a minimum width for the indicator
              }),
              placeholder: (base) => ({
                ...base,
                position: "absolute", // Ensure the placeholder doesn't shift with input
                top: "50%",
                transform: "translateY(-50%)", // Vertically center the placeholder
                pointerEvents: "none", // Disable interaction on the placeholder
              }),
              multiValue: (base) => ({
                ...base,
                background: "transparent",
                border: "2px solid #3f88a5",
                borderRadius: "10px",
                marginTop: "0.5em",
              }),
              option: (provided, state) => ({
                ...provided,

                backgroundColor: state.isSelected
                  ? "transparent" // Selected option background color
                  : state.isFocused
                  ? "white" // Focused option background color
                  : "white", // Default option background color
                color: state.isSelected ? "black" : "black", // Text color based on state
                cursor: "pointer",
              }),
            }}
          />
        </div>
      </div>

      {selectedProducts && selectedProducts.length > 0 && (
        <>
          <div className="renewable-bar-labels" style={{ marginTop: "5%" }}>
            <span style={{ fontSize: "11px" }}>0</span>
            <span style={{ fontSize: "11px" }}>{Math.round(totalSum / 5)}</span>
            <span style={{ fontSize: "11px" }}>
              {Math.round((totalSum / 5) * 2)}
            </span>
            <span style={{ fontSize: "11px" }}>
              {Math.round((totalSum / 5) * 3)}
            </span>
            <span style={{ fontSize: "11px" }}>
              {Math.round((totalSum / 5) * 4)}
            </span>
            <span style={{ fontSize: "11px" }}>{Math.round(totalSum)}</span>
          </div>
          <div className="renewable-bar-dotted-line"></div>

          <div className="renewable-bar">
            {selectedProducts.map((recoveryType, index) => {
              const value = aggregatedData[recoveryType] || 0;

              // Skip rendering if the value is 0
              if (value === 0) {
                return null;
              }

              // Use logarithmic scaling, adding 1 to avoid log(0) errors
              const scaledValue = Math.log10(value + 1);

              // Calculate widthPercentage using the log-transformed value
              const widthPercentage =
                totalSum > 0
                  ? (scaledValue / Math.log10(totalSum + 1)) * 100
                  : 0;

              const barColor = colors[index % colors.length]; // Use color based on index

              return (
                <div
                  className="renewable-bar-section"
                  key={index}
                  title={`${recoveryType}: ${value.toFixed(2)} mT`} // Tooltip shows actual value
                  style={{
                    width: `${widthPercentage}%`, // Use scaled width
                    backgroundColor: barColor,
                    display: "inline-block",
                    fontSize: "12px",
                    color: "white",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {value}
                </div>
              );
            })}
          </div>
          <div
            className="renewable-legends-container"
            style={{ width: "100%", marginTop: "5%", display: "flex" }}
          >
            {selectedProducts.map((recoveryType, index) => {
              const barColor = colors[index % colors.length]; // Use color based on index
              return (
                <div
                  className="renewable-legend"
                  key={index}
                  style={{ display: "flex", width: "25%" }}
                >
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      className="renewable-legend-color"
                      style={{
                        display: "inline-block",
                        width: "17px",
                        height: "17px",
                        borderRadius: "50%",
                        backgroundColor: barColor,
                        marginRight: "10px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ fontSize: "12px" }}>{recoveryType}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default CommonBarComponent;

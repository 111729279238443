import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import { Row, Col } from "react-bootstrap";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEnvironment from "./TopComponentEnvironment";
import Overview from "./Overview";
import DashboardCharts from "./DashboardCharts";
import DashboardChartsTwo from "./DashboardChartsTwo";
import TotalEmissionGenerated from "../Emission/TotalCommissionGranted";
import TotalEmissionGeneratedSingle from "./TotalEmissionGenerated";
import TotalEnergyConsumption from "../Energy/FrameworkOne/TotalEnergyConsumption";
import TotalWaterConsumed from "./TotalWwaterConsumed";
import TotalWasteGenerated from "./TotalWasteDisposed";
import TotalWaterWithdrawn from "./TotalWaterWithdrawn,";
import WasteDisposedEn from "./WasteDisposedEn";
import GreenProcurementRate from "./GreenProcurementRate";
import IndustryBenchmarkComparison from "./IndustryBenchmark";
import ProgressTowardsESGTargets from "./ProgressTowardsESGCharts";
import EnergyConsumptionBar from "./EnergyConsumptionBar";
import DonutChart from "./DonutChart";
import ProgressTowardsESG from "./ProgressTowardsESG";
import SplineAreaChart from "./SplineAreaChart";
import Renewable from "./RenewableAndNoneRenew";
import WaterWithdraw from "./TotalWaterWithdraw";
import IndustryStandards from "./IndustryStandards";
import WaterDonutChart from "./DonutChart";
import WasteDonutChart from "./WasteDonutChart";
import ScopeBar from "./ScopeBar";
import EnvironmentSingleLocSingleTime from "./EnvironmentSingleLocSingleTime";
import EnvironmentSingleLocMultipleTime from "./EnvironmentSingleLocMultipleTime";

const Environment = ({
  locationOption,
  timePeriods,
  keyTab,
  sectorQuestionAnswerDataForGraph,
  financialYearId,
  frameworkValue,
  graphData,
}) => {
  console.log("location", graphData);

  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [companyFramework, setCompanyFramework] = useState();
  const [renewableEnergy, setRenewableEnergy] = useState();
  const [nonRenewableEnergy, setNonRenewableEnergy] = useState();

  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  function convertMixedData(mixedArray) {
    return mixedArray.map((data) => {
      if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
        const flattenedAnswer = data.answer.flat();
        const summedValue = flattenedAnswer.reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );

        return {
          questionId: data.questionId,
          sourceId: data.sourceId,
          answer: {
            process: 1,
            readingValue: summedValue.toString(),
            unit: "KG",
          },
          title: data.title,
          question_details: data.question_details,
          formDate: data.formDate,
          toDate: data.toDate,
        };
      } else {
        return {
          ...data,
          answer: {
            ...data.answer,
            readingValue: data.answer.readingValue || "0",
          },
        };
      }
    });
  }

  const scopeOne = Number(
    JSON.parse(
      sectorQuestionAnswerDataForGraph?.[0]?.["answer"] || "[]"
    )[0]?.[1] || 0
  );

  const scopeTwo = Number(
    JSON.parse(
      sectorQuestionAnswerDataForGraph?.[0]?.["answer"] || "[]"
    )[1]?.[1] || 0
  );

  const totalScope = scopeOne + scopeTwo;

  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataDischarge, setFilteredDataDischarge] = useState([]);
  const [matchedData, setMatchedData] = useState([]);
  const [matchedDataDischarge, setMatchedDataDischarge] = useState([]);
  const [matchedDataWaste, setMatchedDataWaste] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [totalDischarge, setTotalDischarge] = useState(0);
  const [totalSumWaste, setTotalSumWaste] = useState(0);
  const [lastWeekActivities, setLastWeekActivities] = useState({});
  const [totalConsumptionRenewable, setTotalConsumptionRenewable] = useState(0);
  const [totalConsumptionNonRenewable, setTotalConsumptionNonRenewable] =
    useState(0);
  const [totalConsumption, setTotalConsumption] = useState(0);
  const [timePeriodValues, setTimePeriodValues] = useState([]);
  const [wasteDisposal, setWasteDisposal] = useState();
  const [wasteRecovered, setWasteRecovered] = useState();
  const [totalSumTwo, setTotalSumTwo] = useState();
  const [totalSumThree, setTotalSumThree] = useState();

  const [waterType, setWaterType] = useState();
  const [waterDischarge, setWaterDischarge] = useState();
  const [matchedWaterDis, setMatchedWaterDis] = useState();
  const [matchedDataWater, setMatchedDataWater] = useState();

  const [totalConsumptionTwo, setTotalConsumptionTwo] = useState(0);
  const [brief,setBrief]=useState();

  const wasteSeries = [
    "Plastic",
    "E-Waste",
    "Biomedical",
    "Construction and demolition",
    "Battery",
    "Radioactive",
    "Other hazardous wastes",
    "Other non-hazardous wastes",
  ];

  const wasteSeriesTwo = [
    "Incineration",
    "Landfilling",
    "Other disposal operations",
  ];

  const recoverySeries = ["Re-cycled", "Re-used", "Other Recovery Options"];
  // const [companyFramework, setCompanyFramework] = useState([]);

  // Effect to filter data based on titles and match time periods
  useEffect(() => {
    if (companyFramework && companyFramework.includes(1)) {
      // this below code is for energy
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);

      const energyRenew =
        graphData?.filter(
          (item) => item.title === "Energy Consumption from Renewable Sources"
        ) || [];
      const nonEnergyRenew =
        graphData?.filter(
          (item) =>
            item.title === "Energy Consumption from Non-renewable Sources"
        ) || [];

      // Safeguard against undefined timePeriodsArray
      const newEnergyRenew = energyRenew.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );
      const newNonEnergyRenew = nonEnergyRenew.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );

      const finalEnergy = newEnergyRenew.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalNonEnergy = newNonEnergyRenew.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      setRenewableEnergy(finalEnergy);
      setNonRenewableEnergy(finalNonEnergy);

      if (!Array.isArray(finalEnergy) || finalEnergy.length === 0) {
        setTotalConsumptionRenewable(0);
        return;
      }

      const firstObject = finalEnergy[0];

      // Extract all the rows but skip the first one
      const rowOptions = firstObject.question_details
        .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
        .slice(1) // Skip the first row
        .map((detail) => detail.option)
        .reverse(); // Extract the "option" values

      const aggregatedValuesEnergy = rowOptions.map((_, index) =>
        finalEnergy.reduce((acc, obj) => {
          const value =
            obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
          return (
            acc +
            (value === "NA" || !value || value === "No" || value === "Yes"
              ? 0
              : parseFloat(value || 0))
          );
        }, 0)
      );

      console.log(aggregatedValuesEnergy);

      const totalEnergy = aggregatedValuesEnergy.reduce(
        (sum, value) => sum + value,
        0
      );
      setTotalConsumptionRenewable(totalEnergy);

      const firstObjectNon = finalNonEnergy[0];

      // Extract all the rows but skip the first one
      const rowOptionsNon = firstObjectNon.question_details
        .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
        .slice(1) // Skip the first row
        .map((detail) => detail.option)
        .reverse(); // Extract the "option" values

      const aggregatedValuesNon = rowOptionsNon.map((_, index) =>
        finalNonEnergy.reduce((acc, obj) => {
          const value =
            obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
          return (
            acc +
            (value === "NA" || !value || value === "No" || value === "Yes"
              ? 0
              : parseFloat(value || 0))
          );
        }, 0)
      );

      const totalNon = aggregatedValuesNon.reduce(
        (sum, value) => sum + value,
        0
      );
      setTotalConsumptionNonRenewable(totalNon);

      //this below code is for waste

      const newWasteType =
        graphData?.filter((item) => item.title === "Waste Management") || [];
      const newWasteDischarge =
        graphData?.filter((item) => item.title === "Waste Disposal") || [];
      const newWasteRecovery =
        graphData?.filter((item) => item.title === "Waste Recovery") || [];

      // Update state with filtered data

      // Filter matched data based on time periods
      const newMatchedDataWaste = newWasteType.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );

      const newMatchedDataWasteDischarge = newWasteDischarge.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );

      const wasteRecovery = newWasteRecovery.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );

      const finalWaste = newMatchedDataWaste.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyTwo = newMatchedDataWasteDischarge.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyThree = wasteRecovery.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      setWasteDisposal(finalEnergyTwo);
      setMatchedDataWaste(finalWaste);
      setWasteRecovered(finalEnergyThree);

      // Calculate total sum for waste management
      const aggregatedWaste = wasteSeries.reduce((acc, wasteType) => {
        acc[wasteType] = 0; // Initialize each waste type with 0
        return acc;
      }, {});

      let sum = 0;
      finalEnergy.forEach((item) => {
        const answers = item.answer?.[0] || [];
        answers.forEach((value, index) => {
          if (wasteSeries[index]) {
            const numericValue =
              value === "NA" || !value ? 0 : parseFloat(value);
            aggregatedWaste[wasteSeries[index]] += numericValue;
            sum += numericValue; // Add to total sum
          }
        });
      });

      setTotalSum(sum);

      // Calculate total sum for waste disposal
      const aggregatedTwo = wasteSeriesTwo.reduce((acc, disposalType) => {
        acc[disposalType] = 0; // Initialize each disposal type with 0
        return acc;
      }, {});

      finalEnergyTwo.forEach((item) => {
        const answers = item.answer?.[0] || [];
        answers.forEach((value, index) => {
          if (wasteSeriesTwo[index]) {
            const numericValue =
              value === "NA" || !value ? 0 : parseFloat(value);
            aggregatedTwo[wasteSeriesTwo[index]] += numericValue;
          }
        });
      });

      const seriesData = wasteSeriesTwo.map(
        (disposalType) => aggregatedTwo[disposalType] || 0
      );
      const totalSumTwo = seriesData.reduce((sum, value) => sum + value, 0);
      setTotalSumTwo(totalSumTwo);

      // Calculate total sum for waste recovery
      const aggregatedThree = recoverySeries.reduce((acc, recoveryType) => {
        acc[recoveryType] = 0; // Initialize each recovery type with 0
        return acc;
      }, {});

      finalEnergyThree.forEach((item) => {
        const answers = item.answer?.[0] || [];
        answers.forEach((value, index) => {
          if (recoverySeries[index]) {
            const numericValue =
              value === "NA" || !value ? 0 : parseFloat(value);
            aggregatedThree[recoverySeries[index]] += numericValue;
          }
        });
      });

      const totalSumThree = Object.values(aggregatedThree).reduce(
        (sum, value) => sum + value,
        0
      );
      setTotalSumThree(totalSumThree);

      // the below code is for water

      const waterSeries = [
        "Surface Water",
        "Ground Water",
        "Third Party Water",
        "Municipal Water",
        "Seawater / Desalinated Water",
        "Others",
      ];

      const waterSeriesTwo = [
        "To Surface Water",
        "To Ground Water",
        "To Sea Water",
        "Sent to other parties",
        "Others",
      ];

      // Safeguard against undefined graphData or timePeriods
      setTimePeriodValues(timePeriodsArray);

      const newWaterTypeEnv =
        graphData?.filter((item) => item.title === "Water withdrawal") || [];
      const newWaterDischarge =
        graphData?.filter(
          (item) => item.title === "Details of Water Discharge"
        ) || [];
      setWaterType(newWaterTypeEnv);
      setWaterDischarge(newWaterDischarge);

      // Safeguard against undefined timePeriodsArray
      const newMatchedDataWater = newWaterTypeEnv.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );
      const newMatchedDataWaterDischarge = newWaterDischarge.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );

      const finalWater = newMatchedDataWater.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalNonWater = newMatchedDataWaterDischarge.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      setMatchedWaterDis(finalNonWater);
      setMatchedDataWater(finalWater);

      if (
        !Array.isArray(newMatchedDataWater) ||
        newMatchedDataWater.length === 0
      ) {
        setTotalConsumption(0);
        return;
      }

      const aggregatedValues = waterSeries.map((_, index) =>
        newMatchedDataWater.reduce((acc, obj) => {
          const value =
            obj.answer && Array.isArray(obj.answer) && obj.answer[index]?.[0];
          return acc + (value === "NA" || !value ? 0 : parseFloat(value || 0));
        }, 0)
      );

      const total = aggregatedValues.reduce((sum, value) => sum + value, 0);
      setTotalConsumption(total);

      const aggregated = waterSeriesTwo.map((_, index) =>
        newMatchedDataWaterDischarge.reduce((acc, obj) => {
          const value =
            obj.answer && Array.isArray(obj.answer) && obj.answer[index]
              ? obj.answer[index]
                  .slice(0, 2)
                  .reduce(
                    (sum, val) =>
                      sum + (val === "NA" || !val ? 0 : parseFloat(val)),
                    0
                  )
              : 0;
          return acc + value;
        }, 0)
      );

      const totalTwo = aggregated.reduce((sum, value) => sum + value, 0);
      setTotalConsumptionTwo(totalTwo);

      // others
    }
  }, [graphData, timePeriods]);

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const activityData = {
        "Total Energy": {
          number: `${totalConsumptionRenewable+totalConsumptionNonRenewable} GJ`,
          questionId: [],
        },
        "Total Emission": {
          number: `${totalScope} tCO2e`,
          questionId: [],
        },
        "Total Water": {
          number: `${totalConsumption} KL`,
          questionId: [],
        },
        "Total Waste": {
          number: `${totalSum} (mt)`,
          questionId: [],
        },
        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekActivities(activityData);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, [totalSum, totalScope, totalConsumption]);

  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue
        .map((value) => value?.id)
        .filter((id) => id !== undefined);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  return (
    <div className="progress-container">
      {companyFramework &&
        companyFramework.length > 0 &&
        companyFramework.includes(1) && (
          <div className="topcompo">
            {lastWeekActivities && (
              <TopComponentEnvironment
                lastWeekActivities={lastWeekActivities}
                icons={icons}
              />
            )}
          </div>
        )}

      {companyFramework &&
      companyFramework.length &&
      companyFramework.includes(1) ? (
        (timePeriodValues &&
          locationOption.length === 1 &&
          timePeriodValues.length === 1) ||
        (locationOption.length > 1 &&
          timePeriodValues.length === 1 &&
          keyTab === "combined") ? (
          <>
            <EnvironmentSingleLocSingleTime
              graphData={graphData}
              keyTab={keyTab}
              locationOption={locationOption}
              brief={brief}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              matchedDataWater={matchedDataWater}
              matchedWaterDis={matchedWaterDis}
              companyFramework={companyFramework}
            />
          </>
        ) : (locationOption.length > 1 &&
            timePeriodValues.length > 1 &&
            keyTab === "combined") ||
          (locationOption.length > 1 && timePeriodValues.length === 1) ||
          (locationOption.length == 1 && timePeriodValues.length > 1) ? (
          <>
            <EnvironmentSingleLocMultipleTime
              graphData={graphData}
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              matchedDataWater={matchedDataWater}
              matchedWaterDis={matchedWaterDis}
              companyFramework={companyFramework}
            />
          </>
        ) : (
          <></>
        )
      ) : (timePeriodValues &&
          locationOption.length === 1 &&
          timePeriodValues.length === 1) ||
        (locationOption.length > 1 &&
          timePeriodValues.length === 1 &&
          keyTab === "combined") ? (
        <>
          <EnvironmentSingleLocSingleTime
            graphData={graphData}
            keyTab={keyTab}
            companyFramework={companyFramework}
            brief={brief}
          />
        </>
      ) : (locationOption.length > 1 &&
          timePeriodValues.length > 1 &&
          keyTab === "combined") ||
        (locationOption.length > 1 && timePeriodValues.length === 1) ||
        (locationOption.length == 1 && timePeriodValues.length > 1) ? (
        <>
          <EnvironmentSingleLocMultipleTime
            graphData={graphData}
            keyTab={keyTab}
            brief={brief}
            locationOption={locationOption}
            timePeriods={timePeriods}
            timePeriodValues={timePeriodValues}
            companyFramework={companyFramework}
          />
        </>
      ) : (
        timePeriodValues && (
          <>
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{
                  gap: "20px",
                  height: "65vh",
                  marginBottom: "3%",
                  width: "76vw",
                }}
              >
                <div className="nothing" style={{ width: "50%" }}>
                 
                </div>
                <div className="nothing" style={{ width: "50%" }}>

                  {/* <WaterRecycledMultiLoc brief={brief}/> */}
                </div>
              </div>
              <div
                className="d-flex flex-row flex-space-between"
                style={{
                  gap: "20px",
                  height: "65vh",
                  marginBottom: "3%",
                  width: "76vw",
                }}
              >
                <div className="nothing" style={{ width: "50%" }}>
                {/* <WaterTreatedMultiLoc brief={brief}/> */}
                </div>
                <div className="nothing" style={{ width: "50%" }}>

                </div>
              </div>
              {/* <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%", width: "76vw" }}
            ></div> */}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Environment;

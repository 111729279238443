import React from "react";
import Table from "react-bootstrap/Table";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import { useEffect, useState } from "react";
import "./teamworkload.css";
import "../ProgressBySector/sectorprogress.css";
import { NavLink } from "react-router-dom";

const TeamWorkLoad = ({ fromDate, toDate, financialYearId, handleTeamWorkloadData,user }) => {
  const [teamWorkloadData, setTeamWorkloadData] = useState([]);
  const [activeLegend, setActiveLegend] = useState(["accepted","answered"]); // To track clicked legends

  const onSelect = (data) => {
    // navigate("/#/reporting-modules/all-module", {
    //   state: {
    //     data: item?.accepted,
    //     percentageAccepted: item?.percentageAccepted,
    //   },
    // });
    // localStorage.setItem("questionIds", data);
    // window.location.href = "/#/reporting-modules/all-module";
  };
  const teamWorkloadProgess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
      {},
      { fromDate: fromDate, toDate: toDate, financialYearId: financialYearId },
      "GET"
    );
    if (isSuccess) {
      setTeamWorkloadData(data?.data?.teamWorkloadResults);
    
      handleTeamWorkloadData(data?.data?.teamWorkloadResults, user)
    }
  };
  useEffect(() => {
    teamWorkloadProgess();
  }, [fromDate, toDate, financialYearId]);

  const handleLegendClick = (legendType) => {
    if (activeLegend.includes(legendType)) {
      setActiveLegend(activeLegend.filter((item) => item !== legendType));
    } else {
      setActiveLegend([...activeLegend, legendType]);
    }
  };

  const renderBars = (item) => {
    const isUser = user === "user";
    const bars = [];

    // Handle Accepted for Audit
    if (
      user === "audit" &&
      Number(item?.accepted) !== 0 &&
      activeLegend.includes("accepted")
    ) {
      bars.push(
        <NavLink
          key="accepted"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.acceptedQuestionIds)
            );
          }}
          to={{
            pathname: "audit-history",
            state: {
              reportingQuestion: item.acceptedQuestionIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageAccepted)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar accepted"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.accepted)}`}</span>
          </div>
        </NavLink>
      );
    }



    if (
      user === "audit" &&
      Number(item?.accepted) !== 0 &&
      activeLegend.includes("rejected")
    ) {
      bars.push(
        <NavLink
          key="rejected"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.rejectedQuestionIds)
            );
          }}
          to={{
            pathname: "audit-history",
            state: {
              reportingQuestion: item.rejectedQuestionIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageRejected)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar rejected"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.rejected)}`}</span>
          </div>
        </NavLink>
      );
    }



    // Handle Answered for User
    if (
      isUser &&
      Number(item?.answered) !== 0 &&
      activeLegend.includes("answered")
    ) {
      bars.push(
        <NavLink
          key="answered"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.answeredQuestionIds)
            );
          }}
          to={{
            pathname: "reporting-modules/all-module",
            state: {
              reportingQuestion: item.answeredQuestionIds,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageAnswered)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar answered"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.answered)}`}</span>
          </div>
        </NavLink>
      );
    }

    if (
      isUser &&
      Number(item?.answered) !== 0 &&
      activeLegend.includes("assigned")
    ) {
      bars.push(
        <NavLink
          key="assigned"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.assignedQuestionIds)
            );
          }}
          to={{
            pathname: "reporting-modules/all-module",
            state: {
              reportingQuestion: item.assignedQuestionIds,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageAssigned)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar answered"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.assigned)}`}</span>
          </div>
        </NavLink>
      );
    }

    if (
      isUser &&
      Number(item?.accepted) !== 0 &&
      activeLegend.includes("accepted")
    ) {
      bars.push(
        <NavLink
          key="accepted"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.acceptedQuestionIds)
            );
          }}
          to={{
            pathname: "reporting-modules/all-module",
            state: {
              reportingQuestion: item.acceptedQuestionIds,
      
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageAnswered)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar answered"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.answered)}`}</span>
          </div>
        </NavLink>
      );
    }

    // Handle Rejected for User
    if (
      isUser &&
      Number(item?.rejected) !== 0 &&
      activeLegend.includes("rejected")
    ) {
      bars.push(
        <NavLink
          key="rejected"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.rejectedQuestionIds)
            );
          }}
          to={{
            pathname: "rejected-history",
            state: {
              reportingQuestion: item.rejectedQuestionIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageRejected)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar rejected"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.rejected)}`}</span>
          </div>
        </NavLink>
      );
    }

    // Handle Not Responded for Both User and Audit
    if (
      Number(item?.notResponded) !== 0 &&
      activeLegend.includes("not-responded")
    ) {
      bars.push(
        <NavLink
          key="not-responded"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.notRespondedQuestionIds)
            );
          }}
          to={{
            pathname: "not-responded-history",
            state: {
              reportingQuestion: item.notRespondedQuestionIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageUnresponded)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar not-responded"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.notResponded)}`}</span>
          </div>
        </NavLink>
      );
    }

    return bars;
  };

  return (
    // <div
    //   style={{
    //     width: "100%",
    //     background: "white",
    //     borderRadius: "10px",
    //     padding: "10px",
    //     height: "100%",
    //   }}
    // >
    //   <div
    //     style={{
    //       width: "100%",
    //       background: "white",

    //       height: "10%",
    //     }}
    //   >
    //     <div
    //       style={{
    //         color: "#011627",
    //         fontSize: 22,
    //         height: "100%",
    //         paddingLeft: "5px",
    //         fontFamily: "Open Sans",
    //         fontWeight: "600",
    //       }}
    //     >
    //       {user === "user" ? "Data Owner Work Load" : "Auditor Work Load"}
    //     </div>
    //   </div>
    //   <div
    //     className="legend"
    //     style={{
    //       width: "100%",
    //       background: "white",
    //       borderRadius: "10px",

    //       height: "5%",
    //     }}
    //   >
    //     {user == "audit" && (
    //       <div className="legend-item">
    //         <div className="legend-color accepted"></div>
    //         <div className="legend-text">Accepted</div>
    //       </div>
    //     )}
    //     {user == "user" && (
    //       <div className="legend-item">
    //         <div className="legend-color answered"></div>
    //         <div className="legend-text">Answered</div>
    //       </div>
    //     )}
    //     {user == "user" && (
    //       <div className="legend-item">
    //         <div className="legend-color accepted"></div>
    //         <div className="legend-text">Accepted</div>
    //       </div>
    //     )}
    //     {user == "user" && (
    //       <div className="legend-item">
    //         <div className="legend-color rejected"></div>
    //         <div className="legend-text">Rejected</div>
    //       </div>
    //     )}
    //     {user == "user" && (
    //       <div className="legend-item">
    //         <div className="legend-color assigned"></div>
    //         <div className="legend-text">Assigned</div>
    //       </div>
    //     )}
    //     {user == "audit" && (
    //       <div className="legend-item">
    //         <div className="legend-color rejected"></div>
    //         <div className="legend-text">Rejected</div>
    //       </div>
    //     )}
    //     <div className="legend-item">
    //       <div className="legend-color not-responded"></div>
    //       <div className="legend-text">not-responded</div>
    //     </div>
    //   </div>
    //   <div
    //     style={{ height: "80%" }}
    //     className="scrollable-table mt-2 scroll-container"
    //   >
    //     <Table style={{ height: "100%" }} className="">
    //       <thead>
    //         <tr>
    //           <th className="name-column">Name</th>
    //           <th className="progress-column">Progress</th>
    //           {/* <th className="count-column">Count</th> */}
    //         </tr>
    //       </thead>

    //       <tbody className="scroll-container">
    //         {teamWorkloadData.map((item) => {
    //           const isUser = user === "user";
    //           if (
    //             (isUser && item?.totalAssignedQuestionForAnswered !== 0) ||
    //             (!isUser && item?.totalAssignedQuestionForAudit !== 0)
    //           ) {
    //             return (
    //               <tr key={item.userId}>
    //                 <td className="name-column">{`${item.firstName} ${item.lastName}`}</td>
    //                 <td className="progress-column">
    //                   <div className="bar-container" style={{ width: "100%" }}>
    //                     {user === "audit" && Number(item?.accepted) !== 0 && (
    //                       <NavLink
    //                         onClick={() => {
    //                           localStorage.setItem(
    //                             "reportingQuestion",
    //                             JSON.stringify(item.acceptedQuestionIds)
    //                           );
    //                         }}
    //                         to={{
    //                           pathname: "audit-history",
    //                           state: {
    //                             reportingQuestion: item.acceptedQuestionIds,
    //                             userId: item?.userId,
    //                           },
    //                         }}
    //                         style={{
    //                           display: "block",
    //                           width: `${Number(item?.percentageAccepted)}%`,
    //                           padding: "0px",
    //                           margin: "0px",
    //                         }}
    //                       >
    //                         <div
    //                           className="bar accepted"
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                             justifyContent: "center",
    //                             color: "white",
    //                             width: "100%",
    //                           }}
    //                         >
    //                           <span className="bar-label">{`${Number(
    //                             item?.accepted
    //                           )}`}</span>
    //                         </div>
    //                       </NavLink>
    //                     )}
    //                        {user === "user" && Number(item?.accepted) !== 0 && (
    //                       <NavLink
    //                         onClick={() => {
    //                           localStorage.setItem(
    //                             "reportingQuestion",
    //                             JSON.stringify(item.acceptedQuestionIds)
    //                           );
    //                         }}
    //                         to={{
    //                           pathname: "reporting-modules/all-module",
    //                           state: {
    //                             reportingQuestion: item.acceptedQuestionIds,

    //                           },
    //                         }}
    //                         style={{
    //                           display: "block",
    //                           width: `${Number(item?.percentageAccepted)}%`,
    //                           padding: "0px",
    //                           margin: "0px",
    //                         }}
    //                       >
    //                         <div
    //                           className="bar accepted"
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                             justifyContent: "center",
    //                             color: "white",
    //                             width: "100%",
    //                           }}
    //                         >
    //                           <span className="bar-label">{`${Number(
    //                             item?.accepted
    //                           )}`}</span>
    //                         </div>
    //                       </NavLink>
    //                     )}
    //                     {user === "user" && Number(item?.answered) !== 0 && (
    //                       <NavLink
    //                         onClick={() => {
    //                           localStorage.setItem(
    //                             "reportingQuestion",
    //                             JSON.stringify(item.answeredQuestionIds)
    //                           );
    //                         }}
    //                         to={{
    //                           pathname: "reporting-modules/all-module",
    //                           state: {
    //                             reportingQuestion: item.answeredQuestionIds,
    //                           },
    //                         }}
    //                         style={{
    //                           display: "block",
    //                           width: `${Number(item?.percentageAnswered)}%`,
    //                           padding: "0px",
    //                           margin: "0px",
    //                         }}
    //                       >
    //                         <div
    //                           className="bar answered"
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                             justifyContent: "center",
    //                             color: "white",
    //                             width: "100%",
    //                           }}
    //                         >
    //                           <span className="bar-label">{`${Number(
    //                             item?.answered
    //                           )}`}</span>
    //                         </div>
    //                       </NavLink>
    //                     )}
    //                     {user === "audit" && Number(item?.rejected) !== 0 && (
    //                       <NavLink
    //                         onClick={() => {
    //                           localStorage.setItem(
    //                             "reportingQuestion",
    //                             JSON.stringify(item.rejectedQuestionIds)
    //                           );
    //                         }}
    //                         to={{
    //                           pathname: "reporting-modules/all-module",
    //                           state: {
    //                             reportingQuestion: item.rejectedQuestionIds,
    //                           },
    //                         }}
    //                         style={{
    //                           display: "block",
    //                           width: `${Number(item?.percentageRejected)}%`,
    //                           padding: "0px",
    //                           margin: "0px",
    //                         }}
    //                       >
    //                         <div
    //                           className="bar rejected"
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                             justifyContent: "center",
    //                             color: "white",
    //                             width: "100%",
    //                           }}
    //                         >
    //                           <span className="bar-label">{`${Number(
    //                             item?.rejected
    //                           )}`}</span>
    //                         </div>
    //                       </NavLink>
    //                     )}
    //                       {user === "user" && Number(item?.rejected) !== 0 && (
    //                       <NavLink
    //                         onClick={() => {
    //                           localStorage.setItem(
    //                             "reportingQuestion",
    //                             JSON.stringify(item.rejectedQuestionIds)
    //                           );
    //                         }}
    //                         to={{
    //                           pathname: "reporting-modules/all-module",
    //                           state: {
    //                             reportingQuestion: item.rejectedQuestionIds,
    //                           },
    //                         }}
    //                         style={{
    //                           display: "block",
    //                           width: `${Number(item?.percentageRejected)}%`,
    //                           padding: "0px",
    //                           margin: "0px",
    //                         }}
    //                       >
    //                         <div
    //                           className="bar rejected"
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                             justifyContent: "center",
    //                             color: "white",
    //                             width: "100%",
    //                           }}
    //                         >
    //                           <span className="bar-label">{`${Number(
    //                             item?.rejected
    //                           )}`}</span>
    //                         </div>
    //                       </NavLink>
    //                     )}
    //                         {user === "user" && item?.assigned && Number(item.assigned) !== 0  (
    //                       <NavLink
    //                         onClick={() => {
    //                           localStorage.setItem(
    //                             "reportingQuestion",
    //                             JSON.stringify(item.rejectedQuestionIds)
    //                           );
    //                         }}
    //                         to={{
    //                           pathname: "reporting-modules/all-module",
    //                           state: {
    //                             reportingQuestion: item.assignedQuestionIds,
    //                           },
    //                         }}
    //                         style={{
    //                           display: "block",
    //                           width: `${Number(item?.percentageAssigned)}%`,
    //                           padding: "0px",
    //                           margin: "0px",
    //                         }}
    //                       >
    //                         <div
    //                           className="bar assigned"
    //                           style={{
    //                             display: "flex",
    //                             alignItems: "center",
    //                             justifyContent: "center",
    //                             color: "white",
    //                             width: "100%",
    //                           }}
    //                         >
    //                           <span className="bar-label">{`${Number(
    //                             item?.assigned
    //                           )}`}</span>
    //                         </div>
    //                       </NavLink>
    //                     )}

    //                     {((isUser && Number(item?.answerNotResponded) != 0) ||
    //                       (!isUser && item?.auditorNotResponded != 0)) && (
    //                       <>
    //                         <NavLink
    //                           onClick={() => {
    //                             const reportingQuestion =
    //                               user === "user"
    //                                 ? item.answerNotRespondedIds
    //                                 : item.auditorNotRespondedIds;
    //                             localStorage.setItem(
    //                               "reportingQuestion",
    //                               JSON.stringify(reportingQuestion)
    //                             );
    //                           }}
    //                           to={{
    //                             pathname:
    //                               user === "user"
    //                                 ? "reporting-modules/all-module"
    //                                 : "audit-listing/all-module",
    //                             state: {
    //                               reportingQuestion:
    //                                 user === "user"
    //                                   ? item.answerNotRespondedIds
    //                                   : item.auditorNotRespondedIds,
    //                             },
    //                           }}
    //                           style={{
    //                             display: "block",
    //                             width: `${
    //                               user === "user"
    //                                 ? Number(
    //                                     item?.percentageAnsweredUnresponded
    //                                   )
    //                                 : Number(item?.percentageAuditorUnresponded)
    //                             }%`,
    //                             padding: "0px",
    //                             margin: "0px",
    //                           }}
    //                         >
    //                           <div
    //                             className="bar not-responded"
    //                             style={{
    //                               display: "flex",
    //                               alignItems: "center",
    //                               justifyContent: "center",
    //                               color: "white",
    //                               width: "100%",
    //                             }}
    //                           >
    //                             <span className="bar-label">{`${Number(
    //                               user === "user"
    //                                 ? item?.answerNotResponded
    //                                 : item?.auditorNotResponded
    //                             )}`}</span>
    //                           </div>
    //                         </NavLink>
    //                       </>
    //                     )}
    //                   </div>
    //                 </td>
    //                 {/* <td
    //                   className="count-column"
    //                   // style={{display:"flex",justifyContent:"center",alignItems:"center"}}

    //                   onClick={() => onSelect(item.questionIds)}
    //                 >
    //                   <div
    //                     style={{
    //                       width: "100%",
    //                       display: "flex",
    //                       alignItems: "center",
    //                       justifyContent: "center",
    //                     }}
    //                   >
    //                     <div
    //                       className="count-container"
    //                       style={{ width: "75%" }}
    //                     >
    //                       <div
    //                         style={{
    //                           width: "100%",
    //                           display: "flex",
    //                           justifyContent: "center",
    //                         }}
    //                       >
    //                         <span>{`${
    //                           isUser ? item?.answered : item?.accepted
    //                         }/${
    //                           isUser
    //                             ? item?.totalAssignedQuestionForAnswered
    //                             : item?.totalAssignedQuestionForAudit
    //                         }`}</span>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </td> */}
    //               </tr>
    //             );
    //           }
    //           return null;
    //         })}
    //       </tbody>
    //     </Table>
    //   </div>
    // </div>

    <div
      style={{
        width: "100%",
        background: "white",
        borderRadius: "10px",
        padding: "10px",
        height: "100%",
      }}
    >
      <div style={{ width: "100%", background: "white", height: "10%" }}>
        <div
          style={{
            color: "#011627",
            fontSize: 22,
            height: "100%",
            paddingLeft: "5px",
            fontFamily: "Open Sans",
            fontWeight: "600",
          }}
        >
          {user === "user" ? "Data Owner Work Load" : "Auditor Work Load"}
        </div>
      </div>
      

      <div
        style={{ height: "80%" }}
        className="scrollable-table mt-2 scroll-container"
      >
        <Table style={{ height: "100%" }} className="">
          <thead>
            <tr>
              <th className="name-column">Name</th>
              <th className="progress-column">Progress</th>
            </tr>
          </thead>
          <tbody className="scroll-container">
            {teamWorkloadData.map((item) => {
              const isUser = user === "user";
              if (
                (isUser && item?.totalAssignedQuestionForAnswered !== 0) ||
                (!isUser && item?.totalAssignedQuestionForAudit !== 0)
              ) {
                return (
                  <tr key={item.userId}>
                    <td className="name-column">{`${item.firstName} ${item.lastName}`}</td>
                    <td className="progress-column">
                      <div className="bar-container" style={{ width: "100%" }}>
                        {renderBars(item)}
                      </div>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </Table>
      </div>

      <div
        className="legend"
        style={{
          width: "100%",
          background: "white",
          borderRadius: "10px",
          height: "5%",
        }}
      >
        {user === "audit" && (
          <div
            className={`legend-item ${
              activeLegend.includes("accepted") ? "active" : ""
            }`}
            onClick={() => handleLegendClick("accepted")}
            style={{cursor:"pointer"}}
          >
            <div className="legend-color accepted"></div>
            <div className="legend-text">Accepted</div>
          </div>
        )}
        {user === "user" && (
          <>
            <div
              className={`legend-item ${
                activeLegend.includes("answered") ? "active" : ""
              }`}
              onClick={() => handleLegendClick("answered")}
              style={{cursor:"pointer"}}
            >
              <div className="legend-color answered"></div>
              <div className="legend-text">Answered</div>
            </div>
            <div
              className={`legend-item ${
                activeLegend.includes("accepted") ? "active" : ""
              }`}
              onClick={() => handleLegendClick("accepted")}
              style={{cursor:"pointer"}}
            >
              <div className="legend-color accepted"></div>
              <div className="legend-text">Accepted</div>
            </div>
            <div
              className={`legend-item ${
                activeLegend.includes("rejected") ? "active" : ""
              }`}
              onClick={() => handleLegendClick("rejected")}
              style={{cursor:"pointer"}}
            >
              <div className="legend-color rejected"></div>
              <div className="legend-text">Rejected</div>
            </div>
            <div
              className={`legend-item ${
                activeLegend.includes("assigned") ? "active" : ""
              }`}
              onClick={() => handleLegendClick("assigned")}
              style={{cursor:"pointer"}}
            >
              <div className="legend-color assigned"></div>
              <div className="legend-text">Assigned</div>
            </div>
          </>
        )}
        <div
          className={`legend-item ${
            activeLegend.includes("not-responded") ? "active" : ""
          }`}
          onClick={() => handleLegendClick("not-responded")}
          style={{cursor:"pointer"}}
        >
          <div className="legend-color not-responded"></div>
          <div className="legend-text">Not Responded</div>
        </div>
      </div>
    </div>
  );
};

export default TeamWorkLoad;
